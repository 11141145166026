import { useRef } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import generatePDF from "react-to-pdf";
import { exportToExcel } from 'react-json-to-excel';

const ModalReporte = (props) => {
  const {
    estado,
    onCloseModal,
    data,
    data2,
    date,
    date2,
    compraventa,
    formadepago,
  } = props;
  const _ = require("lodash");
  const sum = _.sumBy(data, (rowsDatas) => Number(rowsDatas.totalFactura));
  const tableRef = useRef(null);
  const onDownload = () => {

    for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data2.length; j++) {
            data.push({
                producto: data2[j].productox,
              });
        }
       
      }
      exportToExcel(data, 'downloadfilename')
  };

  return (
    <>
      <Modal show={estado} onHide={onCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            Reporte Fecha: <strong>{date}</strong> al <strong>{date2}</strong>{" "}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Button className="mb-2" onClick={onDownload}>
            {" "}
            Descargar excel{" "}
          </Button>
          <Button
            className="mb-2 ml-2"
            onClick={() => generatePDF(tableRef, { filename: `${date}.pdf` })}
          >
            Descargar PDF
          </Button>
          <br></br>
          <div ref={tableRef} id="content-id">
            <div className="d-flex justify-content-center">
              <h2>
                Rendición Fecha {date} al {date2}{" "}
              </h2>
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <th
                    style={{
                      color: "black",
                      backgroundColor: "yellow",
                      fontSize: 10,
                    }}
                  >
                    <strong>Fecha</strong>
                  </th>
                  <th
                    style={{
                      color: "black",
                      backgroundColor: "yellow",
                      fontSize: 10,
                    }}
                  >
                    <strong>N. Factura</strong>
                  </th>
                  <th
                    style={{
                      color: "black",
                      backgroundColor: "yellow",
                      fontSize: 10,
                    }}
                  >
                    <strong>Razon Social</strong>
                  </th>
                  <th
                    style={{
                      color: "black",
                      backgroundColor: "yellow",
                      fontSize: 10,
                    }}
                  >
                    <strong>Ruc</strong>
                  </th>
                  <th
                    style={{
                      whiteSpace: "pre-wrap",
                      color: "black",
                      backgroundColor: "yellow",
                      fontSize: 10,
                    }}
                  >
                    <strong>Descripcion</strong>
                  </th>
                  <th
                    style={{
                      color: "black",
                      backgroundColor: "yellow",
                      fontSize: 10,
                    }}
                  >
                    <strong>{formadepago}</strong>
                  </th>
                  <th
                    style={{
                      display: `${compraventa}`,
                      color: "black",
                      backgroundColor: "yellow",
                      fontSize: 10,
                    }}
                  >
                    <strong>Compr.</strong>
                  </th>
                  <th
                    style={{
                      color: "black",
                      backgroundColor: "yellow",
                      fontSize: 10,
                    }}
                  >
                    <strong>Tipo Factura</strong>
                  </th>
                  <th
                    style={{
                      color: "black",
                      backgroundColor: "yellow",
                      fontSize: 10,
                    }}
                  >
                    <strong>Monto</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => {
                  for (let i = 0; i < data2.current.length; i++) {
                    if (data2.current[i].idfactura == item.idFactura) {
                      item.producto = data2.current[i].producto;
                    }
                  }
                  return (
                    <tr key={item.idFactura}>
                      <td style={{ fontSize: 10 }}>
                        <strong>{item.fechafactura}</strong>
                      </td>
                      <td style={{ whiteSpace: "pre-wrap", fontSize: 10 }}>
                        <strong>
                          {item.pf1}-{item.pf2}-{item.numerofactura}
                        </strong>
                      </td>
                      <td style={{ whiteSpace: "pre-wrap", fontSize: 10 }}>
                        <strong>{item.razonsocial}</strong>
                      </td>
                      <td style={{ fontSize: 10 }}>
                        <strong>{item.ruc}</strong>
                      </td>
                      <td style={{ whiteSpace: "pre-wrap", fontSize: 10 }}>
                        <strong>{item.producto}</strong>{" "}
                      </td>
                      <td style={{ fontSize: 10 }}>
                        <strong>{item.formadepago}</strong>
                      </td>
                      <td style={{ display: `${compraventa}`, fontSize: 10 }}>
                        <strong>{item.numerodecomprobante}</strong>
                      </td>
                      <td style={{ fontSize: 10 }}>
                        <strong>{item.tipoFactura}</strong>
                      </td>
                      <td style={{ whiteSpace: "pre-wrap", fontSize: 10 }}>
                        <strong>{item.totalFactura}</strong>
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ display: `${compraventa}` }}></td>
                  <td
                    style={{
                      color: "black",
                      backgroundColor: "yellow",
                      fontSize: 18,
                    }}
                  >
                    <span>Total</span>
                  </td>
                  <td
                    style={{
                      color: "black",
                      backgroundColor: "yellow",
                      fontSize: 18,
                    }}
                  >
                    <span>{sum}</span>
                  </td>
                </tr>
              </tbody>
            </Table>

            <div className="d-flex justify-content-between bd-highlight  ">
              <img style={{ width: "20%" }} src="../../../../eethsalogo.jpg" />
              <div className="align-self-end">
                <h3>
                  <span className="fw-bold me-2   "> Controlado por:</span>
                  <span className="fw-bold me-2">____________</span>
                </h3>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalReporte;
