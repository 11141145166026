import Button from "react-bootstrap/Button";
import { Fragment} from "react";

function TableRows2({rowsData, deleteTableRows, handleChange}) {
   
    return(
        
        rowsData.map((data, index)=>{
            const {total}= data;
           
            return(
                <Fragment  >
                <tr >
                <td><input type="number"   defaultValue={0} onChange={(evnt)=>(handleChange(index, evnt))} name="cantidad" className="form-control"/> </td>
                <td><input type="text"   onChange={(evnt)=>(handleChange(index, evnt))} name="producto" className="form-control"/> </td>
                <td><input type="number"   name="precio"   onChange={(evnt)=>(handleChange(index, evnt))} className="form-control"/></td>
                <td><select  name="iva"   onChange={(evnt)=>(handleChange(index, evnt))}  className="form-select"> <option value="10" >10</option>
                 <option value="5">5</option></select></td>
                <td><input type="number"    defaultValue={total}   name="total" className="form-control" disabled/> </td>
                <td><Button size="sm" variant="danger" onClick={(evnt)=>(deleteTableRows(index)
                    

                    )}>x</Button></td>
            </tr>
            </Fragment>
            )
        })
   
    )
    
}
export default TableRows2;