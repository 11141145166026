import React, {
  Fragment,
  useCallback,
  useEffect,
  useReducer,
  useState,
  useMemo,
} from "react";
import { Link } from "react-router-dom";
import { Card, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { TailSpin } from  'react-loader-spinner'
import { COLUMNS } from "./Columns";
import { GlobalFilter } from "./GlobalFilter";
//import './table.css';
import "./filtering.css";
import ModalAgregarClientes from "../sis/ModalFacturacion/ModalAgregarCliente";
import ModalCliente from "../sis/ModalFacturacion/ModalCliente";
import { getAllClientes, saveCliente } from "../../../Api/cliente";

const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
        typeModal: state.typeModal,
        onReturnValue: state.onReturnValue,
      };
    case "END":
      return { ...curShow, isShow: false };
  }
};
const Clientes = () => {
  const Editdata = [{
    nombre:"",
    razonsocial:"",
    ruc:"",
    Ciudad:"",
    direccion:"",
    telefono:"",
    correo:""
  }]
  const [loading, setLoading] = useState(true);
  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,
    typeModal: null,
    onReturnValue: null,
  });
  const [data, setdata] = useState([]);

  const assigmentValue = useCallback(async () => {
    try {
      const result = await getAllClientes();
      setdata([...result]);
      setLoading(false);
      
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  });
  useEffect(() => {
    assigmentValue();
  }, []);
  const handleShow4 = () => {
    dispatchReducer({ type: "SHOW", typeModal: "CLIENTE" });
  };
  const onCloseModal = () => {
    dispatchReducer({ type: "END" });
  };
  const columns = useMemo(() => COLUMNS, []);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;
  const onReturnDataCliente = async (data) => {
    try {
      Swal.fire({
        title: 'Cargando',
        html: 'Cargando  datos',// add html attribute if you want or remove
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
       
    });
      const result = await saveCliente({ ...data, isNew: true });

      if (result) {
        if (result) {
          const listResult = await getAllClientes();
          setdata([...listResult]);
          Swal.close();
          Swal.fire({
            title: "Cliente Cargado",
            html: `Se cargo correctalmente el cliente`,
            icon: "success",
            confirmButtonColor: 'black'
          });
          dispatchReducer({ type: "END" });
        }
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };

  const { globalFilter, pageIndex } = state;
  
  return (
    <Fragment>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body pb-0">
            <div className="row justify-content-between">
              <div className="col-auto">
                <Card.Title>Clientes</Card.Title>
              </div>
              <div className="col-auto">
                <h5>
                  <Button onClick={handleShow4}>Agregar</Button>
                </h5>
              </div>
            </div>
          </div>
          <div className="chart-wrapper">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <GlobalFilter
                    filter={globalFilter}
                    setFilter={setGlobalFilter}
                  />
                      <TailSpin
                height="30"
                width="30"
                color="Black"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={loading}
                />
                <br></br>
                  <table
                    {...getTableProps()}
                    className="table dataTable display"
                  >
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                              {column.render("Header")}
                              {column.canFilter
                                ? column.render("Filter")
                                : null}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                
                    <tbody {...getTableBodyProps()} className="">
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              
                              return (
                                
                                <td {...cell.getCellProps()} className="p-2">
                                  {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'}
                                  
                                  <Link to= "/FichaCliente" state={{ id: row.values.idClientes }} >
                                    {cell.render("Cell")}{" "}
                                  </Link>{" "}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-between">
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                      {""}
                    </span>
                    <span className="table-index">
                      Go to page :{" "}
                      <input
                        type="number"
                        className="ml-2"
                        defaultValue={pageIndex + 1}
                        onChange={(e) => {
                          const pageNumber = e.target.value
                            ? Number(e.target.value) - 1
                            : 0;
                          gotoPage(pageNumber);
                        }}
                      />
                    </span>
                  </div>
                  <div className="text-center mb-3">
                    <div className="filter-pagination  mt-3">
                      <button
                        className=" previous-button"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                      >
                        {"<<"}
                      </button>

                      <button
                        className="previous-button"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                      >
                        Previous
                      </button>
                      <button
                        className="next-button"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                      >
                        Next
                      </button>
                      <button
                        className=" next-button"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                      >
                        {">>"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {reducer.isShow && (
        <ModalAgregarClientes
          estado={reducer.isShow}
          onCloseModal={onCloseModal}
          onReturnValue={onReturnDataCliente}
          Editdata={Editdata}
        />
      )}
      {reducer.isShow2 && (
        <ModalCliente estado={reducer.isShow2} onCloseModal={onCloseModal} />
      )}
    </Fragment>
  );
};
export default Clientes;
