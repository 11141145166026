import { CALL_API2 } from "../Utils/const";
import { getAuthToken } from "../context/auth-context";


export async function getAllCostos() {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/Costos`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}

export async function getCostosbyID(idcostos) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API2}/Costos/${idcostos}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message);
  }
  return data.result;
}
export async function saveCostos(timbradoObject) {
  const { isNew, idcostos } = timbradoObject;
  const token = getAuthToken();
  let urlSend = `${CALL_API2}/Costos`;

  let method = "POST";

  if (timbradoObject.isNew) delete timbradoObject.isNew;
  if (timbradoObject.idCostos) delete timbradoObject.idCostos;

  if (!isNew) {
    urlSend = `${urlSend}/${idcostos}`;
    method = "PUT";
  }

  const result = await fetch(urlSend, {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(timbradoObject),
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.isSaved;
}
export async function deleteCostos(idCostos) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API2}/costos/${idCostos}`, {
    headers: { Authorization: `Bearer ${token}` },
    method: "DELETE",
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }
  return data.isDeleted;
}