import React, {
  Fragment,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";

import ModalFDP from "../sis/ModalOpciones/ModalFDP";
import { Col, Card, Table, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { getAllFormaPago, saveFormaPago } from "../../../Api/formaPago";
const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
        onReturnValue: state.onReturnValue,
        TimbradoObject: state.TimbradoObject,
      };
    case "END":
      return { ...curShow, isShow: false };
  }
};

const Oformasdepago = () => {
  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,
    TimbradoObject: null,
    onReturnValue: null,
  });

  const [listFormadepago, setListFormadepago] = useState([]);
  const onLoadValuesFDP = useCallback(async () => {
    try {
      const listResult = await getAllFormaPago();
      setListFormadepago([...listResult]);
    } catch (err) {
      console.log(err);
    }
  });
  useEffect(() => {
    onLoadValuesFDP();
  }, []);
  const onAgregarFDP = () => {
    dispatchReducer({
      type: "SHOW",
    });
  };

  const onCloseModal = () => {
    dispatchReducer({ type: "END" });
  };

  const onReturnFDP = async (data) => {
    try {
      const result = await saveFormaPago({ ...data, isNew: true });

      if (result) {
        if (result) {
          Swal.fire({
            title: "Forma de pago Cargado",
            html: `Se cargo correctalmente `,
            icon: "success",
          });
          const listResult = await getAllFormaPago();
          setListFormadepago([...listResult]);
          dispatchReducer({ type: "END" });
        }
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };

  return (
    <Fragment>
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Formas de Pago </Card.Title>
            <Button onClick={onAgregarFDP}>Agregar</Button>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <strong>Forma de Pago</strong>
                  </th>
                
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {listFormadepago.map((item, index) => (
                  <tr key={index}>
                    <td><h3>{item.nombre}</h3></td>
                   
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
      {reducer.isShow && (
        <ModalFDP estado={reducer.isShow} onCloseModal={onCloseModal} />
      )}
      {reducer.isShow && (
        <ModalFDP
          estado={reducer.isShow}
          onCloseModal={onCloseModal}
          onReturnValue={onReturnFDP}
        />
      )}
    </Fragment>
  );
};
export default Oformasdepago;
