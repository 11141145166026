import React, { useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import {FaBook } from 'react-icons/fa';
export function  NavMenuToggle(){
    setTimeout(()=>{	
      let mainwrapper = document.querySelector("#main-wrapper");
      if(mainwrapper.classList.contains('menu-toggle')){
        mainwrapper.classList.remove("menu-toggle");
      }else{
        mainwrapper.classList.add("menu-toggle");
      }
    },200);
}

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );
  return (
    <div className="nav-header">
      <Link to="/facturas" className="brand-logo"> 
       <FaBook/>
          <div className="brand-title">
          
          <h2 style={{color: "white"}}>Contabilidad</h2>
          </div>       
      </Link>

      <div
          className="nav-control"
          onClick={() => {
              setToggle(!toggle);
               openMenuToggle();
               NavMenuToggle();
               //SideBarOverlay();
          }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
            <span className="line"></span><span className="line"></span><span className="line"></span>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="22" y="11" width="4" height="4" rx="2" fill="#2A353A"/>
              <rect x="11" width="4" height="4" rx="2" fill="#2A353A"/>
              <rect x="22" width="4" height="4" rx="2" fill="#2A353A"/>
              <rect x="11" y="11" width="4" height="4" rx="2" fill="#2A353A"/>
              <rect x="11" y="22" width="4" height="4" rx="2" fill="#2A353A"/>
              <rect width="4" height="4" rx="2" fill="#2A353A"/>
              <rect y="11" width="4" height="4" rx="2" fill="#2A353A"/>
              <rect x="22" y="22" width="4" height="4" rx="2" fill="#2A353A"/>
              <rect y="22" width="4" height="4" rx="2" fill="#2A353A"/>
            </svg>	
        </div>
      </div>
    </div>
  );
};

export default NavHader;
