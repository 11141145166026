import { CALL_API2 } from "../Utils/const";
import { getAuthToken } from "../context/auth-context";

export async function getAllMonedas() {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/moneda`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}

export async function getMonedaById(idMoneda) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API2}/moneda/${idMoneda}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }
  return data.result;
}

export async function saveMoneda(monedaObject) {
  const { isNew, idMoneda } = monedaObject;
  const token = getAuthToken();
  let urlSend = `${CALL_API2}/moneda`;

  let method = "POST";

  if (monedaObject.isNew) delete monedaObject.isNew;
  if (monedaObject.idMoneda) delete monedaObject.idMoneda;

  if (!isNew) {
    urlSend = `${urlSend}/${idMoneda}`;
    method = "PUT";
  }

  const result = await fetch(urlSend, {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(monedaObject),
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.isSaved;
}

export async function deleteMoneda(idMoneda) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API2}/sucursal/${idMoneda}`, {
    headers: { Authorization: `Bearer ${token}` },
    method: "DELETE",
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }
  return data.isDeleted;
}
