
import { jsPDF } from "jspdf";

export default function genPDF({ datosfactura, detallefactura,isCredito,TextoNumber,sum1,sum2}) {
  const doc = new jsPDF({
    unit: 'px',
    orientation: 'landscape',
    format: 'A4'
  });
  if (datosfactura.direccion==null)
  {
    datosfactura.direccion=""
  }
  if (datosfactura.telefono==null)
  {
    datosfactura.telefono=""
  }
  datosfactura.iva5 = datosfactura.iva5? datosfactura.iva5:datosfactura.gravado05
  datosfactura.iva10 = datosfactura.iva10? datosfactura.iva10:datosfactura.gravado10
  datosfactura.totalmonto = datosfactura.totalmonto? datosfactura.totalmonto : datosfactura.totalFactura
  datosfactura.ivatotal = datosfactura.ivatotal ? datosfactura.ivatotal : Number(datosfactura.gravado05+datosfactura.gravado10)
  datosfactura.Subtotal5 = datosfactura.Subtotal5 ? datosfactura.Subtotal5: sum1
  datosfactura.Subtotal10 = datosfactura.Subtotal10 ? datosfactura.Subtotal10: sum2
  datosfactura.fechafactura = datosfactura.fechafactura ? datosfactura.fechafactura : new Date().toLocaleDateString()
  const writePage = (offsetX = 0) => {

    /* Separacion entre item del listado*/
    let gapRow = 20;

    /* Cambiar segun booleano para saber si es Contado o Credito */
    let isContado = isCredito
    let casillaX = offsetX + 259 + (isContado ? 28 : 0);

    doc.setFont(undefined, 'bold')
    doc.setFontSize(8)
    /* Casilla */
    doc.text("X", casillaX+3, 52)

    doc.setFontSize(6)
    doc.setFont(undefined, 'normal')

    /* Fecha de emision */
    doc.text(datosfactura.fechafactura, offsetX + 60, 52)

    /* Razon Social */
    doc.text(datosfactura.razonsocial, offsetX + 70, 73-10)

    /* RUC / CI */
    doc.text(datosfactura.ruc, offsetX + 52, 80-10)

    /* Direccion */
    doc.text(datosfactura.direccion, offsetX + 125, 80-10)
    /*tel*/
    doc.text(datosfactura.telefono, offsetX + 257, 80-10)

    /*Bucle para iterar lista de productos */
    for (const [index, obj] of detallefactura.entries()) {
      addRow(obj, offsetX-3, 106-10 + (index* gapRow))
    }

    /* Subtotal 5 */
    doc.text(String(datosfactura.Subtotal5), offsetX + 230, 408-9)

    /* Subtotal 10 */
    doc.text(String(datosfactura.Subtotal10), offsetX + 270, 408-9)

    /* Total a pagar en letras */
    doc.text(TextoNumber, offsetX + 65, 415-9,
      { maxWidth: 180 })

    /* Total a pagar en numeros */
    doc.text(String(datosfactura.totalmonto), offsetX + 269, 415-9)

    /* Total iva5 */
    doc.text(String(datosfactura.iva5), offsetX + 100, 428-7)

    /* Total iva10 */
    doc.text(String(datosfactura.iva10), offsetX + 165, 428-7)

    /* Total Total iva */
    doc.text(String(datosfactura.ivatotal), offsetX + 240, 428-7)
  }

  /* Funcion que añade filas a al listado pdf */
  const addRow = (data, x = 0, y) => {
    doc.setFont(undefined, 'bold')
    doc.setFontSize(8)
    doc.text(String(data.cantidad), x + 14, y)
    doc.text(String(data.artcod), x + 38, y)
    doc.setFont(undefined, 'normal')
    doc.setFontSize(8)
    doc.text(data.producto, x + 68, y,
      {maxWidth: 70,
      }
      )
    doc.text(String(data.precio), x + 165, y)
    doc.text(String(data.exenta), x + 198, y)
    doc.text(String(data.cinco), x + 228, y)
    doc.text(String(data.diez), x + 262, y)

  }



  writePage()

  writePage(330)

  /*doc.save()*/
  doc.autoPrint()
  doc.output('dataurlnewwindow')
}
