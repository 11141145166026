import {  useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
const AgregarProveedores = (props) => {
  const { estado,onCloseModal,onReturnValue,Editdata,isnew} = props;

  const [formData3, setValue] = useState([]);
  const handleSubmit2 = (event) => {
    formData3.proveedor=formData3.proveedor? formData3.proveedor:Editdata[0].proveedor
    formData3.razonsocial=formData3.razonsocial? formData3.razonsocial:Editdata[0].razonsocial
    formData3.ruc=formData3.ruc? formData3.ruc:Editdata[0].ruc
    formData3.ciudad=formData3.ciudad? formData3.ciudad:Editdata[0].ciudad
    formData3.direccion=formData3.direccion? formData3.direccion:Editdata[0].direccion
    formData3.telefono=formData3.telefono? formData3.telefono:Editdata[0].telefono
    formData3.Correo=formData3.correo? formData3.Correo:Editdata[0].Correo
    formData3.idProveedor=Editdata[0].idProveedores
    if (formData3.ruc==="" || formData3.razonsocial==="" || formData3.proveedor==="")
    {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Faltan Datos!",
        confirmButtonColor: "black",
      });
    }else
    {
      onReturnValue(formData3,isnew);
    }
  };
  return (
    <>
      <Modal show={estado} onHide={onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Proveedores</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form >
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Proveedor
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                 defaultValue={Editdata[0].proveedor}
                  className="form-control"
                  required
                  onChange={(e) => {
                    setValue({ ...formData3, proveedor: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Razon Social
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  defaultValue={Editdata[0].razonsocial}
                  className="form-control"
                  required
                  onChange={(e) => {
                    setValue({ ...formData3,razonsocial: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Ruc
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  defaultValue={Editdata[0].ruc}
                  className="form-control"
                  required
                  onChange={(e) => {
                    setValue({ ...formData3, ruc: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">Ciudad</label>
              <div className="">
                <input
                  type="text"
                  defaultValue={Editdata[0].ciudad}
                  className="form-control"
                  onChange={(e) => {
                    setValue({ ...formData3, ciudad: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">Direccion</label>
              <div className="">
                <input
                maxLength={45}
                  type="text"
                  defaultValue={Editdata[0].direccion}
                  className="form-control"
                  onChange={(e) => {
                    setValue({ ...formData3, direccion: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">Telefono</label>
              <div className="">
                <input
                  type="text"
                  defaultValue={Editdata[0].telefono}
                  className="form-control"
                  onChange={(e) => {
                    setValue({ ...formData3, telefono: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">Correo</label>
              <div className="">
                <input
                  type="email"
                  defaultValue={Editdata[0].correo}
                  className="form-control"
                  onChange={(e) => {
                    setValue({ ...formData3, correo: e.target.value });
                  }}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onCloseModal}>
            Cerrar
          </Button>
          <Button  onClick={handleSubmit2}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AgregarProveedores;
