import React, { Fragment,useCallback,useEffect,useState,useMemo,useRef, useReducer} from "react";
  import { Row,Col, Card,Button,} from "react-bootstrap";
  import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
  import { COLUMNS } from './Columns';
  import { GlobalFilter } from './GlobalFilter';
  import './filtering.css';
 import { getDetalleFacturaId, getFacturasByQuery} from '../../Api/facturacion'
 import { DownloadTableExcel } from 'react-export-table-to-excel';
 import { TailSpin } from  'react-loader-spinner'
 import Swal from "sweetalert2";
import ModalReporte from "./sis/ModalOpciones/ModalReporteDia";
 const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
        onReturnValue: state.onReturnValue,
        TimbradoObject: state.TimbradoObject,
      };
    case "END":
      return { ...curShow, isShow: false };
    default :
    return { ...curShow, isShow: false };
  }
};
  const Reporte = () => {
    const [reducer, dispatchReducer] = useReducer(showReducer, {
      isShow: false,
      TimbradoObject: null,
      onReturnValue: null,
    });
    const [loading, setLoading] = useState(true);
    const compraventa = "";
    const [filterData, setValue] = useState([]);
    const [data, setdata] = useState([]);
    const [data2, setdata2] = useState([]);
    const data3 = useRef();
    const columns = useMemo( () => COLUMNS, [] )
    const assigmentValue = useCallback(async () => {

      try {
        const producto = {
          fechaInicio: '1991-01-01',
          fechaFin: '1991-01-01',
        }
       
        const result = await getFacturasByQuery(producto);
        for (let i = 0; i < result.length; i++) {
          if (result[i].anulado.data == 0) {
            result[i].anulado.data = "No Anulado";
          } else {
            result[i].anulado.data = "Anulado";
            result[i].totalFactura=0
          }
          
        }
        
        setdata([...result])
        setValue({
          fechaInicio : '1991-01-01',
          fechaFin: '1991-01-01'
        }
        )
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    });
    useEffect(() => {
      assigmentValue();
    }, []);

  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const Filtrar = async () => {
    
      try {
        if (filterData.fechaFin=="")
        {
          filterData.fechaFin="1991-01-01"
        }
        if ((filterData.fechaInicio==""))
        {
          filterData.fechaInicio="1991-01-01"
        }
        const result = await getFacturasByQuery(filterData);
        setdata([...result]);
      } catch (err) {
        console.log(err);
      }
    };
 
    const onAgregarTimbrado = async() => {
      Swal.fire({
        title: 'Generando',
        html: 'Generando Reporte',// add html attribute if you want or remove
        allowOutsideClick: true,
        showCancelButton: true,
        showConfirmButton: false,
       
    });
    
    const producto = {
      fechaInicio:filterData.fecha,
      fechaFin: filterData.fecha2,
    }
      const result = await getFacturasByQuery(producto); 
     
     
      let resulting = result.filter(item => item.anulado.data!=1)
      setdata2([...resulting])
      let productox=''
      let datas=[]
      for (let i = 0; i < resulting.length; i++) {
        const result2= await getDetalleFacturaId(resulting[i].idFactura)
        for (let i = 0; i < result2.length; i++) {
          productox=productox+result2[i].cantidad+" "+result2[i].producto+" "
        }
        datas.push({
          idfactura:result2[0].idFactura,
          producto:productox
        })
        productox=''
      } 
      data3.current=datas
      Swal.close();
      dispatchReducer({
        type: "SHOW",
      });
   
    };
    const onCloseModal = () => {
      dispatchReducer({ type: "END" });
    };
 
  const tableInstance = useTable({
    columns,
    data,	
    initialState : {pageSize: 200,pageIndex : 0}
  }, useFilters, useGlobalFilter, usePagination)
  
  const { 
    getTableProps, 
    getTableBodyProps, 
    headerGroups, 
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance
  
  
  const {globalFilter, pageIndex} = state
  
  const tableRef = useRef(null);
  
    return (
  
        <Fragment>
             <div className="col-md-12">
             <div className="card">
             <div className="card-body pb-0">
             <div className="row justify-content-between">
             <div className="col-auto">
             <Card.Title>Reporte Facturas</Card.Title>
             </div>
             </div>
             </div>

             </div>
             </div>
            <div className="col-md-12">
                <div className="card">
                <div className="card-body pb-0">
                <div className="row justify-content-between">
                     <div className="col-auto">
                    <Card.Title>Reporte Gral.</Card.Title>
                    </div>
                    <div className="chart-wrapper">
                    <form 
                        id="auFORM">
                      <Row  className="mb-3">
                        <Col className="xl-4">
                        <div className="form-group mb-2 ">
                            <label className="col-form-label">
                             Fecha Inicial
                            </label>
                            <div className="">
                                <input
                                    type="date"
                                    className="form-control"
                                    onChange={(e) => {
                                      setValue({
                                        ...filterData,
                                        fecha: e.target.value,
                                      });
                                    }}
                                   
                                  

                                />
                            </div>
                        </div>
                        <div className="form-group mb-2 ">
                            <label className="col-form-label">
                             Fecha Final
                            </label>
                            <div className="">
                                <input
                                    type="date"
                                    className="form-control"
                                    onChange={(e) => {
                                      setValue({
                                        ...filterData,
                                        fecha2: e.target.value,
                                      });
                                    }}
                                   
                                  

                                />
                            </div>
                        </div>
                        </Col>
                        <Col className="xl-4">
                        
                        </Col>
                        <Col className="xl-4">
                   
                       
                  
                        </Col>
                        </Row>
                        <Button  onClick={onAgregarTimbrado}  className="mb-4 ml-2"> Generar Reporte </Button>
                    </form>
                    </div>
                </div>


               

                </div>

                </div>
            </div>
            <div className="col-md-12">
                <div className="card">
                <div className="card-body pb-0">
                <div className="row justify-content-between">
                     <div className="col-auto">
                    <Card.Title>Reporte Extendido en Excel</Card.Title>
                    </div>
                    <div className="chart-wrapper">
                    <form 
                        id="auFORM">
                      <Row  className="mb-3">
                        <Col className="xl-4">
                        <div className="form-group mb-2 ">
                            <label className="col-form-label">
                              Fecha Inicial
                               
                            </label>
                            <div className="">
                                <input
                                    type="date"
                                    className="form-control"
                                    onChange={(e) => {
                                      setValue({
                                        ...filterData,
                                        fechaInicio: e.target.value,
                                      });
                                    }}
                                   
                                  

                                />
                            </div>
                        </div>
                        
                        </Col>
                        <Col className="xl-4">
                        <div className="form-group mb-2  ">
                            <label className="col-form-label">
                              Fecha Final
                               
                            </label>
                            <div className="">
                                <input
                                    type="date"
                                    className="form-control"
                                    onChange={(e) => {
                                      setValue({
                                        ...filterData,
                                        fechaFin: e.target.value,
                                      });
                                    }}
                                   

                                />
                            </div>
                        </div>
                        </Col>
                        <Col className="xl-4">
                   
                       
                  
                        </Col>
                        </Row>
                    <Button className="mb-2" onClick={Filtrar}>Buscar</Button>
                    </form>
                    </div>
                </div>


               

                </div>

                </div>
            </div>
          <div className="col-md-12">
              <div className="card">
                <div className="card-body pb-0">
                  <div className="row justify-content-between">
                    <div className="col-auto">
                   
                    </div>
                    <div className="col-auto">
                    
                    </div>
                  </div>
                </div>
                <div className="chart-wrapper">
                <div className="card">
        <div className="card-body">
                <DownloadTableExcel
                    filename="Reporte"
                    sheet="users"
                    currentTableRef={tableRef.current}
                >

                   <Button  className="mb-4"> Export excel </Button>

                </DownloadTableExcel>
               
                
          <div className="table-responsive">
          <TailSpin
                height="30"
                width="30"
                color="Black"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={loading}
                />
                <br></br>
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table {...getTableProps()} className="table dataTable display"  ref={tableRef}>
              <thead>
                 {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                        {column.canFilter ? column.render('Filter') : null}
                      </th>
                    ))}
                  </tr>
                 ))}
              </thead> 
              
              <tbody {...getTableBodyProps()} className=""  >
              
                {page.map((row) => {
                  prepareRow(row)
                  return(
                    <tr  {...row.getRowProps()} >
                      {row.cells.map((cell) => {
                        return <td  {...cell.getCellProps()}>{cell.render('Cell')}  </td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{''}
              </span>
              <span className="table-index">
                Go to page : {' '}
                <input type="number" 
                  className="ml-2"
                  defaultValue={pageIndex + 1} 
                  onChange = {e => { 
                    const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
                    gotoPage(pageNumber)
                  } } 
                />
              </span>
            </div>
            <div className="text-center mb-3">	
              <div className="filter-pagination  mt-3">
                <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                
                <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                  Previous
                </button>
                <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                  Next
                </button>
                <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
                </div>
               
              </div>
            </div>
            {reducer.isShow && (
            <ModalReporte
              estado={reducer.isShow}
              onCloseModal={onCloseModal}
              data={data2}
              data2={data3}
              date={filterData.fecha}
              date2={filterData.fecha2}
              compraventa={compraventa}
              formadepago={"Forma de pago"}
            />
          )}  
           
          
  </Fragment> 
    )
  }
  export default Reporte;