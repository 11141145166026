import {  useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import Swal from 'sweetalert2'

const ModalDepositar = (props) => {
    const {
        estado,
        onCloseModal,
        onReturnValue,
        listFormadepago,
        listID

    } = props;
    const [formData1, setValue1] = useState([]);
    const [formData2, setValue2] = useState([]);


    const handleSubmit2 = (event) => {
        event.preventDefault();
        formData1.valueUpdate=formData1.valueUpdate? formData1.valueUpdate: "";
        if (listID.idFac.length===0)
        {
            Swal.fire({
                icon: 'error',
                title: 'Elemento invalido',
                text: 'Selecciones un elemeno minimo',
                
              })
        }else if ( formData1.valueUpdate=="")
        {
            Swal.fire({
                icon: 'error',
                title: 'Metodo de pago',
                text: 'Selecciones Metodo de pago',
                confirmButtonColor: "black"
              })
        }else
    {
        onReturnValue(formData1,formData2);
    }
        
       
       
    }
    return (
        <>

            <Modal
                show={estado}
                onHide={onCloseModal}

            >

                <Modal.Header closeButton>
                    <Modal.Title>Depositar</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form onSubmit={handleSubmit2}
                        id="auFORM">
                      
                        <div className="form-group mb-2 row">
                            <label className="col-form-label">
                            Forma de Pago
                                <span className="text-danger">*</span>
                            </label>
                            <div className="">
                            <Select
                                options={listFormadepago}
                                onChange={(e) => {
                                setValue1({
                                    ...formData1,
                                    valueUpdate: e.value,
                                    FileUpdate:"FORMA_PAGO",
                                });
                              }}
                  />
                            </div>
                        </div>
                        <div className="form-group mb-2 row">
                            <label className="col-form-label">
                              Numero de Comprobante
                                <span className="text-danger">*</span>
                            </label>
                            <div className="">
                                <input
                                    type="text"
                                    className="form-control"
                                    required
                                    onChange={e => { setValue2({
                                         ...formData2, 
                                         valueUpdate: e.target.value,
                                         FileUpdate:"NUMERO_COMPROBANTE",
                                         }) }}
                                  

                                />
                            </div>
                        </div>
                        <br>
                        </br>
                        <Button variant="danger" onClick={onCloseModal}>
                        Cerrar
                    </Button>
                    <Button type="submit" >
                        Guardar
                    </Button>
                    </form>





                </Modal.Body>
               

            </Modal>


        </>
    );
}

export default ModalDepositar;