import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const ModalFDP = (props) => {
  const { estado, onCloseModal, onReturnValue } = props;

  const [formData3, setValue] = useState([]);

  const handleSubmit2 = (event) => {
    event.preventDefault();
    onReturnValue(formData3);
  };
  return (
    <>
      <Modal show={estado} onHide={onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Forma de pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit2}>
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Forma de pago
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  required
                  onChange={(e) => {
                    setValue({ ...formData3, nombre: e.target.value });
                  }}
                />
              </div>
            </div>
           
           
            <Button variant="danger" onClick={onCloseModal}>
              Cerrar
            </Button>
            <Button type="submit">Guardar</Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalFDP;
