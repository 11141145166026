import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
export const COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'idFactura',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Pf1',
		Footer : 'Nombre',
		accessor: 'pf1',
		Filter: ColumnFilter,
	},
	{
		Header : 'Pf2',
		Footer : 'Ruc',
		accessor: 'pf2',
		Filter: ColumnFilter,
	},
	
	{
		Header : 'N Factura',
		Footer : 'Razon Social',
		accessor: 'numerofactura',
		Filter: ColumnFilter,
	},
	{
		Header : 'Total',
		accessor: 'totalFactura',
		Filter: ColumnFilter,
	},
	{
		Header : 'Fecha Emision',
		accessor: 'fechafactura',
		Filter: ColumnFilter,
	},
	{
		Header : 'Forma de pago',
		accessor: 'formadepago',
		Filter: ColumnFilter,
	},
	{
		Header : 'N de comprobante',
		
		accessor: 'numerodecomprobante',
		Filter: ColumnFilter,
	},
]

export const GROUPED_COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id'
	},
	{
		Header : 'Name',
		Footer : 'Name',
		columns: [
			{
				Header : 'First Name',
				Footer : 'First Name',
				accessor: 'first_name'
			},
			{
				Header : 'Last Name',
				Footer : 'Last Name',
				accessor: 'last_name'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Date of  Birth',
				Footer : 'Date of  Birth',
				accessor: 'date_of_birth'
			},
			{
				Header : 'Country',
				Footer : 'Country',
				accessor: 'country',
			},
			{
				Header : 'Phone',
				Footer : 'Phone',
				accessor: 'phone'
			},
		]
	},
]