import Button from "react-bootstrap/Button";
import Select from "react-select";
const handleKeyPress = (e) => {
    if (e.charCode === 45 || e.charCode === 46) {
      return e.preventDefault()
    }
  };
function TableRows({rowsData, deleteTableRows, handleChange,handleChange2,listcostos,handleChangeSelect}) {
   
    return(
        
        rowsData.map((data, index)=>{
            const {Descuento,precio,iva,total}= data;
           
            return(
                <>
                <tbody key={index}>
                <tr >
                <td><input type="number" min="0"    onKeyPress={handleKeyPress}     onChange={(evnt)=>(handleChange2(index, evnt))} name="descuento" className="form-control"/> </td>
                <td><input type="number"  min="1" onKeyPress={handleKeyPress}  onChange={(evnt)=>(handleChange(index, evnt))} name="cantidad" className="form-control"/> </td>
                <td><Select
                    className="mt-2"
                    options={listcostos}
                    name="producto"
                    onChange={(evnt)=>(handleChangeSelect(index, evnt,'producto'))} 
                  /></td>
                <td><input type="number" defaultValue={precio}  name="precio" className="form-control" disabled/></td>
                <td><input type="number"  defaultValue={iva} name="ivaproducto" className="form-control" disabled/></td>
                <td><input type="number"    defaultValue={total}   name="total" className="form-control" disabled/> </td>
                <td><Button size="sm" variant="danger" onClick={(evnt)=>(deleteTableRows(index)
                    

                    )}>x</Button></td>
            </tr>
            </tbody>
            </>
            )
        })
   
    )
    
}
export default TableRows;