export const MenuList = [
   
    //Widget
    {   
        title:'Facturacion',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">receipt_long</i>,
        content : [
            
            {
                title: 'Clientes',
                to : 'Clientes',
            },
            {
                title: 'Facturas',
                to : 'Facturas',
            },
            
        ],
    },
    {   
        title:'Facturas Contado',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">request_quote</i>,
        content : [
            
            {
                title: 'Depositado',
                to : 'depositado',
            },
            {
                title: 'No Depositado',
                to : 'nodepositado',
            },
            
        ],
    },
    {   
        title:'Facturas Credito',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">credit_score</i>,
        content : [
            
            {
                title: 'Pagados',
                to : 'cobranza',
            },
            {
                title: 'No Pagados',
                to : 'vencimientos',
            },
            
        ],
    },
     {   
        title:'Reportes',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">account_balance_wallet</i>,
        to: 'Reporte',
    },
    //Pages
    {
        title:'Compras',
        classsChange: 'mm-collapse',
        iconStyle: <i className="merial-icons">receipt_long</i>,
        content : [
            
                    {
                        title: 'Proveedores',
                        to : 'proveedores',
                    },
                    {
                        title: 'Compras',
                        to : 'compras',
                    },
                    {
                        title: 'Lista de compras',
                        to : 'listadecompras',
                    },
                    {
                        title: 'Reporte Compras',
                        to : 'reportecompras',
                    },
                    
                    //{
                    //    title: 'Moneda',
                  //      to : 'Opcionesdemoneda',
                  //  },
                  
                ],
           
           

    },
    {
        title:'Opciones',
        classsChange: 'mm-collapse',
        iconStyle: <i className="merial-icons">settings</i>,
        content : [
            
                    {
                        title: 'Timbrado',
                        to : 'Opcionesdetimbrado',
                    },
                    {
                        title: 'Sucursal',
                        to : 'Opcionesdesucursal',
                    },
                    {
                        title: 'Puntos de Venta',
                        to : 'Opcionesodv',
                    },
                    //{
                    //    title: 'Moneda',
                  //      to : 'Opcionesdemoneda',
                  //  },
                    {
                        title: 'Formas de pago',
                        to : 'Opcionesformadepago',
                    },
                    {
                        title: 'Servicio/Producto',
                        to : 'Opcionesdecosto',
                    },
                ],
           
           

    },
    
]