import { CALL_API2 } from "../Utils/const";
import { getAuthToken } from "../context/auth-context";

export async function getAllClientes() {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/cliente`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}

export async function getClienteById(idCliente) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API2}/cliente/${idCliente}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }
  return data.result;
}

export async function getSearchClient(sqlQuery) {
  const token = getAuthToken();
  const response = await fetch(
    `${CALL_API2}/search-cliente?sqlQuery=${sqlQuery}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }
  return data.result;
}

export async function saveCliente(clienteObject) {

  const { isNew, idCliente } = clienteObject;
  const token = getAuthToken();
  let urlSend = `${CALL_API2}/cliente`;

  let method = "POST";

  if (clienteObject.isNew) delete clienteObject.isNew;
  if (clienteObject.idCliente) delete clienteObject.idForma;

  if (!isNew) {
    urlSend = `${urlSend}/${idCliente}`;
    method = "PUT";
  }

  const result = await fetch(urlSend, {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(clienteObject),
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.isSaved;
}

export async function deleteClient(idCliente) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API2}/cliente/${idCliente}`, {
    headers: { Authorization: `Bearer ${token}` },
    method: "DELETE",
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }
  return data.isDeleted;
}
