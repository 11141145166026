import { CALL_API2 } from "../Utils/const";
import { getAuthToken } from "../context/auth-context";

export async function getAllFormaPago() {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/forma-pago`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}

export async function getFormaPagoId(idForma) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API2}/forma-pago/${idForma}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }
  return data.result;
}

export async function saveFormaPago(formaObject) {
  const { isNew, idForma } = formaObject;
  const token = getAuthToken();
  let urlSend = `${CALL_API2}/forma-pago`;

  let method = "POST";

  if (formaObject.isNew) delete formaObject.isNew;
  if (formaObject.idForma) delete formaObject.idForma;

  if (!isNew) {
    urlSend = `${urlSend}/${idForma}`;
    method = "PUT";
  }

  console.log(urlSend);

  const result = await fetch(urlSend, {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formaObject),
  });

  const data = await result.json();

  if (!result.ok) {
    console.log(data.message);
    throw new Error(data.message);
  }

  return data.isSaved;
}

export async function deleteFormaPago(idForma) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API2}/forma-pago/${idForma}`, {
    headers: { Authorization: `Bearer ${token}` },
    method: "DELETE",
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }
  return data.isDeleted;
}
