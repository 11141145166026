import React, {
  Fragment,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import Swal from "sweetalert2";
import ModalMoneda from "../sis/ModalOpciones/ModalMoneda";
import {
  getAllMonedas,
  getAllSucursal,
  saveMoneda,
  saveSucursal,
} from "../../../Api/moneda";
import { Col, Card, Table, Button } from "react-bootstrap";

const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
        onReturnValue: state.onReturnValue,
        TimbradoObject: state.SucursalObject,
      };
    case "END":
      return { ...curShow, isShow: false };
  }
};

const Omoneda = () => {
  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,
    SucursalObject: null,
    onReturnValue: null,
  });
  const [listMoneda, setListMoneda] = useState([]);
  const onLoadValuesMoneda = useCallback(async () => {
    try {
      const listResult = await getAllMonedas();
      setListMoneda([...listResult]);
    } catch (err) {
      console.log(err);
    }
  });
  const onAgregarMoneda = () => {
    dispatchReducer({
      type: "SHOW",
    });
  };
  useEffect(() => {
    onLoadValuesMoneda();
  }, []);
  const onCloseModal = () => {
    dispatchReducer({ type: "END" });
  };
  const onReturnMonedadata = async (data) => {
    try {
      const result = await saveMoneda({ ...data, isNew: true });

      if (result) {
        if (result) {
          Swal.fire({
            title: "Cliente Cargado",
            html: `Se cargo correctalmente el cliente`,
            icon: "success",
          });
          const listResult = await getAllMonedas();
          setListMoneda([...listResult]);
          dispatchReducer({ type: "END" });
        }
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };

  return (
    <Fragment>
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Moneda </Card.Title>
            <Button onClick={onAgregarMoneda}>Agregar</Button>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <strong>Codigo</strong>
                  </th>
                  <th>
                    <strong>Moneda</strong>
                  </th>
                  <th>
                    <strong>Opciones</strong>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {listMoneda.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <h3>{item.codigo}</h3>
                      </td>
                      <td>
                        <h3>{item.nombre}</h3>
                      </td>
                      <td>
                        <Button onClick={onAgregarMoneda}>Editar</Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
      {reducer.isShow && (
        <ModalMoneda estado={reducer.isShow} onCloseModal={onCloseModal} />
      )}
      {reducer.isShow && (
        <ModalMoneda
          estado={reducer.isShow}
          onCloseModal={onCloseModal}
          onReturnValue={onReturnMonedadata}
        />
      )}
    </Fragment>
  );
};
export default Omoneda;
