import {  useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Swal from 'sweetalert2'
const ModalModFecha = (props) => {
    const {
        estado,
        onCloseModal,
        onReturnValue,
        id
    } = props;

    const [formData3, setValue] = useState([]);



    const handleSubmit2 = (event) => {
        event.preventDefault();
        formData3.valueUpdate=formData3.valueUpdate? formData3.valueUpdate: "";
        if ( formData3.valueUpdate=="")
        {
            Swal.fire({
                icon: 'error',
                title: 'Ingrese Fecha',
                text: 'Fecha Necesario',
                
              })
        }else
    {
        onReturnValue(formData3);
    }
    }
    return (
        <>

            <Modal
                show={estado}
                onHide={onCloseModal}

            >

                <Modal.Header closeButton>
                    <Modal.Title>Modificar Fecha de Vencimiento</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form onSubmit={handleSubmit2}
                        id="auFORM">
                      
                        <div className="form-group mb-2 row">
                            <label className="col-form-label">
                           Fecha de Vencimiento
                                <span className="text-danger">*</span>
                            </label>
                            <div className="">
                                <input
                                    type="date"
                                    className="form-control"
                                    required
                                    onChange={e => { setValue({ ...formData3,
                                        valueUpdate: e.target.value,
                                         FileUpdate:"FECHA_VENCIMIENTO",
                                         idFactura:id
                                     }) }}
                                  

                                />
                            </div>
                        </div>
                        <br>
                        </br>
                        <Button variant="danger" onClick={onCloseModal}>
                        Cerrar
                    </Button>
                    <Button type="submit" >
                        Guardar
                    </Button>
                    </form>





                </Modal.Body>
               

            </Modal>


        </>
    );
}

export default ModalModFecha;