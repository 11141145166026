import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
export const COLUMNS = [
	{
		Header : '  ID',
		Footer : 'ID',
		accessor: 'idcostos',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header : 'Codigo',
		accessor: 'COD',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header : 'producto',
		accessor: 'producto',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	
	{
		Header : 'Precio Venta',
		
		accessor: 'precioventa',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header : 'Precio Compra',
		Footer : 'telefono',
		accessor: 'preciocompra',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header : 'Iva',
		accessor: 'ivavalor',
		Filter: ColumnFilter,
		disableFilters: true,
	},
]
