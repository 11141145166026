
import { Button, Modal } from "react-bootstrap";

var myStyle = {
    borderRight: '1px solid #222',
    width: '50%',
    height: '8%',
}
var myStyle2 = {
    borderTop: '1px solid #222',

    height: '10%',
}
var myStyle3 = {
    borderTop: '1px solid #222',

    height: '68%',
}

var myStyle5 = {
    borderTop: '1px solid #222',
    height: '4%',
}
var myStyle6 = {


    height: '4%',
}
var myStyle7 = {
    borderTop: '1px solid #222',

    height: '2%',
}
var myStyleex = {
    borderTop: '1px solid #222',

    height: '2%',
}
var myStyleex2 = {
    borderTop: '1px solid #222',

    height: '2%',
}
var myStylh1 = {
    borderRight: '1px solid #222',
    width: '6%',
    fontSize: 8
}
var myStylh2 = {
    borderRight: '1px solid #222',
    width: '8%',
    fontSize: 8
}
var myStylh3 = {
    borderRight: '1px solid #222',
    width: '40%',
    fontSize: 8
}
var myStylh4 = {
    borderRight: '1px solid #222',
    width: '10%',
    fontSize: 8
}
var myStylh5 = {
    borderRight: '1px solid #222',
    width: '7%',
    fontSize: 8
}
var myStylh6 = {
    borderRight: '1px solid #222',
    width: '14%',
    fontSize: 8
}
var myStylh7 = {

    width: '14%',
    fontSize: 8
}
var myStyleventa = {
    borderRight: '1px solid #222',
    width: '64%',
    fontSize: 8
}
var myStyleventa2 = {

    width: '37%',
    fontSize: 8
}
var myStylSubtotal = {
    borderRight: '1px solid #222',
    width: '64%',
    fontSize: 8
}
var myStylSubtotal2 = {
    borderRight: '1px solid #222',
    width: '8%',
    fontSize: 8
}
var myStylSubtotal3 = {
    borderRight: '1px solid #222',
    width: '14%',
    fontSize: 8
}
var myStylSubtotal4 = {

    width: '14%',
    fontSize: 8
}
var myStyltotal = {
    borderRight: '1px solid #222',
    width: '86%',
    fontSize: 12
}
var myStyltotal2 = {

    width: '14%',
    fontSize: 12
}
var myStylh11 = {
   
    width: '30px',
    fontSize: 12
}
var myStylh12 = {
    
    width: '39px',
    fontSize: 12
}
var myStylh13 = {
   
    width: '184px',
    fontSize: 12
}
var myStylh14 = {
    
    width: '48px',
    fontSize: 12
}
var myStylh15 = {
   
    width: '36px',
    fontSize: 12
}
var myStylh16 = {
   
    width: '66px',
    fontSize: 12
}
var myStylh17 = {
    width: '66px',
    fontSize: 12
}
const PreviewCompra = (props) => {
    const { estado, onCloseModal, onReturnValue, datosfactura, detallefactura} = props;
    console.log(datosfactura)
    const handleclick = () => {
        onReturnValue(datosfactura,detallefactura)
    };
    
    return (
        <>
            <Modal show={estado} onHide={onCloseModal} size='l'>
                <Modal.Header closeButton>
                    <Modal.Title>Preview Compra</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={
                        {
                            border: '1px solid black',
                            width: '11.5cm',
                            height: '16.45cm'
                        }
                    }
                    >
                         <div className=''>
                                       
                                        <span  className="ml-1"style={{ fontSize: 12}}>Numero Factura: {datosfactura.numerodefactura} </span>
                                      <br></br>

                                      
                                        <span className="ml-1" style={{ fontSize: 12}}>Razon Social Emisor: {datosfactura.razonsocial}</span><br>
                                        </br>
                                        <span  className="ml-1"style={{ fontSize: 12}}>R.U.C Emisor: {datosfactura.ruc}</span>
                                    
                        
                                       
                        </div>
                        <div style={myStyle2}>
                            <div className=''>
                                <span className="ml-1" style={{ fontSize: 12}}>Fecha Emision: {datosfactura.fecha} </span><br></br>
                                <span  className="ml-1"style={{ fontSize: 12}}>Destino de Compra: {datosfactura.destinocompra?datosfactura.destinocompra:"Caja Chica"} </span>
                                <br></br>

                                <span  className="ml-1"style={{ fontSize: 12}}>Cond. de venta: {datosfactura.terminodepago}</span>
                            </div>
                          
                        </div>
                      
                        <div style={myStyleex} className="d-flex align-items-stretch">
                            <div className='ml-1' style={myStylh1}>cant.</div>
                            <div className='ml-1' style={myStylh2}>ArtCod.</div>
                            <div className='ml-1' style={myStylh3}>Descripcion</div>
                            <div className='ml-1' style={myStylh4}>P. Unitario</div>
                            <div className='ml-1' style={myStylh5}>exentas</div>
                            <div className='ml-1' style={myStylh6}>5%</div>
                            <div className='ml-1' style={myStylh7}>10%</div>
                        </div>
                        <table style={myStyle3} className="d-flex align-items-stretch">
                            <tbody className='' style={{ height:'100%',width:'100%' }}>
                                {detallefactura.map((detallefactura,index) => {
                                    let iva10 =""
                                    let iva5 =""
                                    console.log(detallefactura.iva)
                                    detallefactura.iva==='10'? iva10=detallefactura.total : iva5=detallefactura.total
                                    return (
                                    
                                        <tr key={index} style={{ height:'100%',width:'100%'}}>
                                            <td   style={myStylh11}><span className='d-flex justify-content-center' > {detallefactura.cantidad} </span></td>
                                            <td  style={myStylh12}><span className='d-flex justify-content-center' > {detallefactura.artcod} </span></td>
                                            <td style={myStylh13} ><span> {detallefactura.producto} </span></td>
                                            <td  style={myStylh14}><span> {detallefactura.precio} </span></td>
                                            <td  style={myStylh15}><span>{detallefactura.exenta}</span></td>
                                            <td style={myStylh16}><span> {iva5} </span></td>
                                            <td style={myStylh17}><span> {iva10} </span></td>
                                        </tr>
                                    );
                                })}

                            </tbody>
                        </table>

                     
                        <div style={myStyle5} className="d-flex align-items-stretch">
                            <div className='d-flex justify-content-left' style={myStyltotal}>Total</div>
                            <div className='d-flex justify-content-center' style={myStyltotal2}>{datosfactura.total}</div>
                        </div>
                        <div style={myStyle6}>
                            <div style={myStyle5} className='d-flex justify-content-around'>
                                <div className='d-flex justify-content-left' style={myStyltotal}>IVA (5%) {datosfactura.iva5total}</div>
                                <div className='d-flex justify-content-center' style={myStyltotal}>IVA(10%) {datosfactura.iva10total}</div>
                                <div className='d-flex justify-content-center' style={myStyltotal}>Total Iva: {datosfactura.ivatotal}</div>

                            </div>
                        </div>
                       
                    </div>
                    <br>
</br>
                    <Button onClick={handleclick}>Guardar</Button>
                   
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PreviewCompra;
