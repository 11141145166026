import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
export const COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'idFactura',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'fecha',
		accessor: 'fechafactura',
		Filter: ColumnFilter,
	},
	{
		Header : 'Timbrado',
		accessor: 'timbrado',
		Filter: ColumnFilter,
	},
	{
		Header : 'PF1 (Sucursal)',
		accessor: 'pf1',
		Filter: ColumnFilter,
	},
	{
		Header : 'PF2 (P. de Expedicion)',
		accessor: 'pf2',
		Filter: ColumnFilter,
	},
	
	{
		Header : 'Numero Factura',
		accessor: 'numerofactura',
		Filter: ColumnFilter,
	},
	{
		Header : 'Total',
		accessor: 'totalFactura',
		Filter: ColumnFilter,
	},
	{
		Header : 'Tipo Factura',
		accessor: 'tipoFactura',
		Filter: ColumnFilter,
	},
	{
		Header : 'Forma de Pago',
		Footer : 'Forma de Pago',
		accessor: 'formadepago',
		Filter: ColumnFilter,
	},
	{
		Header : 'Numero Comprobante',
		accessor: 'numerodecomprobante',
		Filter: ColumnFilter,
	},
	{
		Header : 'Razon Social',
		accessor: 'razonsocial',
		Filter: ColumnFilter,
	},
	{
		Header : 'Ruc',
		accessor: 'ruc',
		Filter: ColumnFilter,
	},
	{
		Header : 'Estado',
		accessor: 'anulado.data',
		Filter: ColumnFilter,
	},

]

