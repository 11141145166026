import {  useState } from "react";
import { Button, Modal } from "react-bootstrap";

const ModalAgregarClientes = (props) => {
  const { estado, onCloseModal, onReturnValue,Editdata} = props;

  const [formData3, setValue] = useState([]);
  const handleSubmit2 = (event) => {

    formData3.nombre=formData3.nombre? formData3.nombre:Editdata[0].nombre
    formData3.razonSocial=formData3.razonSocial? formData3.razonSocial:Editdata[0].razonsocial
    formData3.ruc=formData3.ruc? formData3.ruc:Editdata[0].ruc
    formData3.Ciudad=formData3.ciudad? formData3.Ciudad:Editdata[0].Ciudad
    formData3.direccion=formData3.direccion? formData3.direccion:Editdata[0].direccion
    formData3.telefono=formData3.telefono? formData3.telefono:Editdata[0].telefono
    formData3.Correo=formData3.correo? formData3.Correo:Editdata[0].Correo


    event.preventDefault();
    onReturnValue(formData3);
  };
  return (
    <>
      <Modal show={estado} onHide={onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit2} id="auFORM">
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Nombre
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                 defaultValue={Editdata[0].nombre}
                  className="form-control"
                  required
                  onChange={(e) => {
                    setValue({ ...formData3, nombre: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Razon Social
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  defaultValue={Editdata[0].razonsocial}
                  className="form-control"
                  required
                  onChange={(e) => {
                    setValue({ ...formData3, razonSocial: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Ruc
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  defaultValue={Editdata[0].ruc}
                  className="form-control"
                  required
                  onChange={(e) => {
                    setValue({ ...formData3, ruc: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">Ciudad</label>
              <div className="">
                <input
                  type="text"
                  defaultValue={Editdata[0].Ciudad}
                  className="form-control"
                  onChange={(e) => {
                    setValue({ ...formData3, ciudad: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">Direccion</label>
              <div className="">
                <input
                maxLength={45}
                  type="text"
                  defaultValue={Editdata[0].direccion}
                  className="form-control"
                  onChange={(e) => {
                    setValue({ ...formData3, direccion: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">Telefono</label>
              <div className="">
                <input
                  type="text"
                  defaultValue={Editdata[0].telefono}
                  className="form-control"
                  onChange={(e) => {
                    setValue({ ...formData3, telefono: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">Correo</label>
              <div className="">
                <input
                  type="email"
                  defaultValue={Editdata[0].correo}
                  className="form-control"
                  onChange={(e) => {
                    setValue({ ...formData3, correo: e.target.value });
                  }}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onCloseModal}>
            Cerrar
          </Button>
          <Button type="submit" form="auFORM">
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalAgregarClientes;
