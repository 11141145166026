import { useEffect, createContext } from "react";

const AuthContext = createContext({
  token: "",
  isLogged: false,
  login: (token, expirationDate) => {},
  logout: () => {},
});

export function getTokenDuration() {
  const storedTokenDuration = localStorage.getItem("expirationDate");
  const expirationDate = new Date(storedTokenDuration);
  const now = new Date();
  const duration = expirationDate - now;
  return duration;
}

export function getAuthToken() {
  const token = localStorage.getItem("token");
  if (!token) return null;
  const tokenDuration = getTokenDuration();
  if (tokenDuration < 0) return null;
  return token;
}

export function logout() {
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
}

export function login(token, expirationDate) {
  localStorage.setItem("token", token);
  localStorage.setItem("expirationDate", expirationDate);
}

export const AuthContextProvider = (props) => {
  const { children } = props;
  const tokenData = getAuthToken();
  const isAuthUser = tokenData ? true : false;

  useEffect(() => {
    if (!tokenData) logout();
  }, [tokenData]);

  const contextValue = {
    token: tokenData,
    isLogged: isAuthUser,
    login: login,
    logout: logout,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
