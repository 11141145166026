import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import Card from "react-bootstrap/Card";
import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { TYPE_FACTURA,TYPE_DESTINO_COMPRA} from "../../../Utils/const";


import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';

import React, {
  useCallback,
  useEffect,
  useState,
  useReducer,
  useRef,
} from "react";
import Swal from "sweetalert2";
import { getAllProveedores,getProveedorById,saveProveedor} from "../../../Api/Proveedores";
import TableRows2 from "./TableRows";
import AgregarProveedores from "./ModalCompras/AgregarProveedores";
import PreviewCompra from "./ModalCompras/PreviewCompra";
import { insertFacturaCompra} from "../../../Api/facturacion";
const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
        typeModal: state.typeModal,
        onReturnValue: state.onReturnValue,
      };
      case "SHOW2":
      return {
        isShow2: true,
        typeModal: state.typeModal,
        onReturnValue: state.onReturnValue,
      };
    case "END":
      return { ...curShow, isShow: false,isShow2: false };
  }
};
  const GenerarCompra = () => {
    registerLocale("es", es);
    setDefaultLocale('es')
    const Editdata = [{
      proveedor:"",
      razonsocial:"",
      ruc:"",
      ciudad:"",
      direccion:"",
      telefono:"",
      correo:""
    }]
    const isNew=true
    const [ListProveedores,setListProveedores]=useState([])
    const fdp = useRef()
    const tp = useRef()
    const navigate = useNavigate();
    const [RS,SetRS]=useState()
    const [RC,SetRC]=useState()
    const [formData3, setValue] = useState([]);
    const totalfactura = useRef(0);
    const Ivafive = useRef(0);
    const Ivaten = useRef(0);
    const ivatotalfactura  = useRef(0);
    const [reducer, dispatchReducer] = useReducer(showReducer, {
      isShow: false,
      isShow2: false,
      typeModal: null,
      onReturnValue: null,
    });
    useEffect(() => {
      (async () => {
        try {
          Swal.fire({
            title: 'Cargando',
            html: 'Cargando  datos',// add html attribute if you want or remove
            allowOutsideClick: false,
            showCancelButton: false,
            showConfirmButton: false,
           
        });
         
         
          const listResult3 = await getAllProveedores();
 
         
          const resultMapeado2 = listResult3.map((item) => {
            return { label: item.razonsocial+" "+item.ruc, value: item.idProveedores };
          });
          setListProveedores(resultMapeado2);
        } catch (err) {
          console.log(err);
        }
        fdp.current="Efectivo"
        tp.current="Contado"
        Swal.close();
      })();
    }, []);
    const clienteid = useCallback(async (e) => {
      const { value, label } = e;
      Swal.fire({
        title: 'Cargando',
        html: 'Cargando  datos',// add html attribute if you want or remove
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
       
    });
      const resultCliente = await getProveedorById(value);
      Swal.close()
      const { ruc, razonsocial} = resultCliente[0];
      SetRS (razonsocial)
      SetRC (ruc)
      setValue({
        ...formData3,
        ruc: ruc,
        razonsocial:razonsocial
      });
    });

    const handleShow4 = () => {
      dispatchReducer({ type: "SHOW", typeModal: "CLIENTE" });
    };
    const onCloseModal = () => {
      dispatchReducer({ type: "END" });
    };
    const onReturnDataCliente = async (data,isNew) => {
      dispatchReducer({ type: "END" });
      try {
        Swal.fire({
          title: 'Cargando',
          html: 'Cargando  datos',// add html attribute if you want or remove
          allowOutsideClick: false,
          showCancelButton: false,
          showConfirmButton: false,
         
      });
      const result = await saveProveedor({ ...data, isNew });
  
        if (result) {
          if (result) {
            const listResult3 = await getAllProveedores();
            const resultMapeado2 = listResult3.map((item) => {
              return { label: item.razonsocial+" "+item.ruc, value: item.idProveedores };
            });
            setListProveedores(resultMapeado2);
            Swal.close();
            Swal.fire({
              title: "Proveedor  Cargado",
              html: `Se cargo correctalmente el Proveedor`,
              icon: "success",
              confirmButtonColor: 'black'
            });
          }
        }
      } catch (err) {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `${err}`,
        });
        
      }
    };
    const handleChange = (index, evnt) => {
      const { name, value } = evnt.target;
      const rowsInput = [...rowsData];
      rowsInput[index][name] = value;
      rowsInput[index]["total"]=rowsInput[index]["cantidad"]*rowsInput[index]["precio"]
      setRowsData(rowsInput);
      Refresh()
    };
    const [rowsData, setRowsData] = useState([]);
    const addTableRows = () => {
    const rowsInput = {
      cantidad: 0,
      producto: "",
      precio: "",
      iva: "10",
      total: "",
    };
    setRowsData([...rowsData, rowsInput]);
  };
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    const _ = require("lodash");
    const sum = _.sumBy(rows, (rowsDatas) => Number(rowsDatas.total));
    let i;
    let iva5total=0
    let iva10total=0;
    
    for (i in rows) {
      if (rows[i].iva == '5')
      {
        iva5total=iva5total+rows[i].total
      }
      if (rows[i].iva == '10')
      {
        iva10total=iva10total+rows[i].total
      }
    }
   
    totalfactura.current=sum;
    Ivaten.current=(Math.round(iva10total/11));
    Ivafive.current=(Math.round(iva5total/21));
    ivatotalfactura.current=Ivaten.current+Ivafive.current
    setValue({
      ...formData3,
      iva10total: Ivaten.current,
      iva5total:Ivafive.current,
      ivatotal:  ivatotalfactura.current,
      total: sum
    });
    setRowsData(rows); 
  };
  const  Refresh = () => {
    const _ = require("lodash");
    const sum = _.sumBy(rowsData, (rowsDatas) => Number(rowsDatas.total));
    let i;
    let iva5total=0
    let iva10total=0;
    
    for (i in rowsData) {
      if (rowsData[i].iva == '5')
      {
        iva5total=iva5total+rowsData[i].total
      }
      if (rowsData[i].iva == '10')
      {
        iva10total=iva10total+rowsData[i].total
      }
    }
   
    totalfactura.current=sum;
    Ivaten.current=(Math.round(iva10total/11));
    Ivafive.current=(Math.round(iva5total/21));
    ivatotalfactura.current=Ivaten.current+Ivafive.current
    setValue({
      ...formData3,
      iva10total: Ivaten.current,
      iva5total:Ivafive.current,
      ivatotal:  ivatotalfactura.current,
      total: sum
    });
  };
  const SaveFactura = (e) => {
    e.preventDefault()
    formData3.terminodepago= formData3.terminodepago?  formData3.terminodepago:"Contado"
    if (formData3.fecha===undefined)
      {
        Swal.fire({
          title: "Sin Fecha de Emision",
          html: `Favor ingrese Fecha de emision`,
          icon: "warning",
          confirmButtonColor: "#00273a",
  
        });

    }else
    {
      if (formData3.destinocompra===undefined)
        {
          setValue({
            ...formData3,
            destinocompra: "Caja Chica"
          });
        }
      if (formData3.ruc === undefined)
      {
        Swal.fire({
          title: "Sin Razon social y Ruc",
          html: `Favor Ingrese Proveedor`,
          icon: "warning",
          confirmButtonColor: "#00273a",
  
        });
      }
      else if (formData3.total===undefined || rowsData.length === 0 || rowsData[0].producto=="" || rowsData[0].cantidad=="" )
      {
        Swal.fire({
          title: "Sin Detalle ",
          html: `Favor ingrese detalle `,
          icon: "warning",
          confirmButtonColor: "#00273a",
        });
      }else
      {
        dispatchReducer({ type: "SHOW2", typeModal: "CLIENTE" });
      }
    
    }
   
  }
  const onReturnPreview =async (data1,data2) =>
  {
    setValue((prevState) => {
      Swal.fire({
        title: 'Cargando',
        html: 'Cargando  datos',// add html attribute if you want or remove
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
    })
      const currentData = prevState;
      currentData["detalleFactura"] = rowsData;
      return currentData;
    });
    try {
      
      const result = await insertFacturaCompra(formData3, rowsData);
      if (result) {
        if (result) {
          Swal.close()
          Swal.fire({
            title: "Factura de compra Guardada",
            html: `Se Guardo Correctamente`,
            icon: "success",
            confirmButtonColor: 'black'
          });
          
          navigate("/compras");
        }
      }
    } catch (err) {
      Swal.close()
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
    
  }
  const [startDate, setStartDate] = useState("");
  const [startDate2, setStartDate2] = useState("");
  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
    return (
        <form   onSubmit={SaveFactura}>
        <Row>
          <Col>
            <Card className="p-4 p-xl-5 my-3 my-xl-4">
              <Row>
                <Col lg={4} md={4} sm={4}>
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-column">
                      <div className="mb-2">
                      <div className="d-flex flex-row align-items-center">
                    <span className="fw-bold me-2">
                      Fecha de Emision
                    </span>
                    <DatePicker  required    dateFormat='dd/MM/yyyy'  className="form-control" selected={startDate} onChange={date => {setStartDate(date); setValue({
                          ...formData3,
                          fecha:formatDate(date)
                        })} } />
                
                  </div>
                      </div>
                    </div>
                    <div className="d-flex flex-row align-items-center">
                    <span className="fw-bold me-2">
                      Numero de &nbsp;Factura:&nbsp;
                    </span>
                    <input
                      type="text"
                      required
                      className="form-control"
                      onChange={(e) =>{
                        setValue({
                          ...formData3,
                          numerodefactura: e.target.value
                        });
                      }}
                    />
                  </div>
                  </div>
                 
                </Col>
                <Col lg={4} md={4} sm={1}>
                  <div className="d-flex flex-row align-items-center">
                    <span className="fw-bold me-2">Termino de Pago:&nbsp;</span>
                    <Select
                      className="basic-single"
                     options={TYPE_FACTURA}
                      defaultValue={{ label: "Contado", value: "Contado" }}
                      onChange={(e) =>{
                        setValue({
                          ...formData3,
                          terminodepago: e.value
                        });
                      }}
                    />
                    
                  </div>
                 
                </Col>
                <Col lg={4} md={4} sm={1}>
             
                 
                  <div className="d-flex flex-row align-items-center">
                    <span className="fw-bold me-2">
                      Obs. y Forma de pago
                    </span>
                    <textarea
                      className="mt-2 form-control mb-2"
                      onChange={(e) =>{
                        setValue({
                          ...formData3,
                         obs: e.target.value
                        });
                      }}
                    />
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <span className="fw-bold me-2 mb-2">Destino de Compra:&nbsp;</span>
                    <Select
                      className="basic-single mb-2"
                     options={TYPE_DESTINO_COMPRA}
                      defaultValue={{ label: "Caja Chica", value: "Caja Chica" }}
                      onChange={(e) =>{
                        setValue({
                          ...formData3,
                          destinocompra: e.value
                        });
                      }}
                    />
                    
                  </div>
                </Col>
              </Row>
              <div className="d-flex flex-row align-items-center">
                <div className="mt-4">
                  <span style={{ fontSize: 20 }} className="fw-bold">
                    Numero de Factura: &nbsp;
                  </span>
                  <span style={{ fontSize: 20 }} className="fw-bold">
                   {formData3.numerodefactura}
                  </span>
                </div>
              </div>
              <hr className="my-4" />
              <Row className="mb-5">
                <Col>
                  <Form.Label className="fw-bold mb-2">Emisor</Form.Label>
                  <Row className="mb-2">
                    <Col>
                      {" "}
                      <Select
                       options={ListProveedores}
                       onChange={(e) => {
                        clienteid(e);
                      }}
                     
                      />
                    </Col>
                    <Col>
                      {" "}
                      <Button  size="sm"  onClick={handleShow4}>
                        Agregar Emisor
                      </Button>
                    </Col>
                  </Row>
                  <input
                    type="text"
                    className="form-control mb-2"
                    required
                    placeholder="Razon social Emisor"
                    defaultValue={RS}
                    disabled
                  />
                  <input
                    type="text"
                    className="form-control "
                    required
                    placeholder="Ruc Emisor"
                   defaultValue={RC}
                    disabled
                  />
                </Col>
  
                <div className="row">
                  <div className="col">
                    <table className="table">
                      <thead>
                        <tr>
                       
                          <th style={{ color: "black",width:"11%" }}>Cant.</th>
                          <th style={{ color: "black" }}>
                            {" "}
                            Cod. Producto/Servicio
                          </th>
                          <th style={{ color: "black" }}>Precio</th>
                          <th style={{ color: "black",width:"11%" }}>Iva</th>
                          <th style={{ color: "black" }}>Total</th>
                          <th>
                            <Button size="sm"  onClick={addTableRows}> 
                            
                              +
                            </Button>
                          </th>
                        
                        </tr>
                      </thead>
                      <tbody>
                      <TableRows2 
                          rowsData={rowsData}
                          deleteTableRows={deleteTableRows}
                          handleChange={handleChange}
                         />
                     </tbody>
                    </table>
                  </div>
                </div>
              </Row>
  
              <Row className="mt-4 mb-3 justify-content-start">
              <hr className="my-4" />
                <Col lg={6}>
                 
                  <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                    <span className="fw-bold">Iva 10:</span>
                    <span>
                      <span className="small ">{Ivaten.current}</span>
                    </span>
                  </div>
                  <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                    <span className="fw-bold">Iva 5:</span>
                    <span>
                      <span className="small ">{Ivafive.current}</span>
                    </span>
                  </div>
                  <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                    <span className="fw-bold">Iva Total:</span>
                    <span>
                      <span className="small ">{ivatotalfactura.current}</span>
                    </span>
                  </div>
                  <hr />
                  <div
                    className="d-flex flex-row align-items-start justify-content-between"
                    style={{
                      fontSize: "1.125rem",
                    }}
                  >
                    <span className="fw-bold">Total:</span>
                    <span className="fw-bold">{totalfactura.current}</span>
                  </div>
                </Col>
              </Row>
              <Button 
              type="submit"
                variant="primary"
                className="d-block w-100 mb-3"
              >
                Generar Factura
              </Button>
            </Card>
          </Col>
         
        </Row>
        {reducer.isShow && (
          <AgregarProveedores
            estado={reducer.isShow}
            onCloseModal={onCloseModal}
            onReturnValue={onReturnDataCliente}
            Editdata={Editdata}
            isnew={isNew}
          />
        )}
         {reducer.isShow2 && (
          <PreviewCompra
            estado={reducer.isShow2}
            onCloseModal={onCloseModal}
            onReturnValue={onReturnPreview}
            datosfactura={formData3}
            detallefactura={rowsData}

          />
        )}
      </form>
    )
  };
  export default GenerarCompra;