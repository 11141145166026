export const CALL_API2 = "https://eethsa.xyz/eethsa2/api";

export const CONTADO = Object.freeze({
  value: "Contado",
  label: "Contado",
});

export const CREDITO = Object.freeze({ value: "Credito", label: "Credito" });
export const Cajachica = Object.freeze({ value: "Caja Chica", label: "Caja Chica" });
export const Proveedores = Object.freeze({ value: "Proveedores", label: "Proveedores" });
export const Prestadores = Object.freeze({ value: "Prestadores", label: "Prestadores" });
export const TYPE_FACTURA = [CONTADO, CREDITO];
export const TYPE_DESTINO_COMPRA = [Cajachica, Proveedores,Prestadores];
