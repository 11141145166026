import React, {
    Fragment,
    useCallback,
    useEffect,
    useReducer,
    useState,
  } from "react";
import ModalPDV from "../sis/ModalOpciones/ModalPDV"
import {
    Col,
    Card,
    Table,
    Button,  
} from "react-bootstrap";
import { getAllPuntoVenta, savePuntoVenta} from "../../../Api/puntoVenta";
import Swal from "sweetalert2"; 
const showReducer = (curShow, state) => {
    switch (state.type) {
      case "SHOW":
        return {
          isShow: true,
          onReturnValue: state.onReturnValue,
          TimbradoObject: state.SucursalObject,
        };
      case "END":
        return { ...curShow, isShow: false };
    }
  };

const Opuntodeventa = () => {
   
    const [reducer, dispatchReducer] = useReducer(showReducer, {
        isShow: false,
        SucursalObject: null,
        onReturnValue: null,
         });
    const [listSucursal, setListSucursal] = useState([]);
    const onLoadValuesSucursal = useCallback(async () => {
        try {
          const listResult = await getAllPuntoVenta();
          setListSucursal([...listResult]);
        } catch (err) {
          console.log(err);
        }
      });
      useEffect(() => {
        onLoadValuesSucursal();
        }, []);


        const onAgregarSucursal = () => {
            dispatchReducer({
                type: "SHOW",
            });
        };
    const onCloseModal = () => {
        dispatchReducer({ type: "END" });
      };
      const onReturnSucursaldata = async (data) => {
        try {
          const result = await savePuntoVenta({ ...data, isNew: true });
    
          if (result) {
            if (result) {
              Swal.fire({
                title: "Punto de venta cargado",
                html: `Se cargo correctalmente el punto de venta`,
                icon: "success",
              });
              const listResult = await getAllPuntoVenta();
              setListSucursal([...listResult]);
              dispatchReducer({ type: "END" });
            }
          }
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${err}`,
          });
        }
        };


    return (

        <Fragment>
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Punto de venta </Card.Title><Button  onClick={onAgregarSucursal}>Agregar</Button>
                </Card.Header>
                <Card.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>
                                    <strong>Codigo</strong>
                                </th>
                                <th>
                                    <strong>Punto de venta</strong>
                                </th>
                                <th>
                                    <strong>Opciones</strong>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {listSucursal.slice(0, 11).map((item) => {
                                return (
                                    <tr key={item.codigo}>
                                        <td><h3>{item.codigo}</h3></td>
                                        <td><h3>{item.nombre}</h3></td>
                                       
                                        <td>
                                            <Button onClick={onAgregarSucursal}>Editar</Button>
                                        </td>

                                    </tr>
                                );
                            })}

                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Col>
           {reducer.isShow && (
            <ModalPDV
              estado={reducer.isShow}
              onCloseModal={onCloseModal}
            />
          )}  
          {reducer.isShow && (
            <ModalPDV
              estado={reducer.isShow}
              onCloseModal={onCloseModal}
              onReturnValue={onReturnSucursaldata}
            />
          )}  
</Fragment> 
    )
}
export default Opuntodeventa;