import React, {
    Fragment,
    useCallback,
    useEffect,
    useReducer,
    useState,
  } from "react";
import Swal from "sweetalert2"; 
import ModalSucursal from "../sis/ModalOpciones/ModalSucursal"
import { getAllSucursal, saveSucursal } from "../../../Api/sucursal";
import {Col, Card,Table,Button,} from "react-bootstrap";
const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
        onReturnValue: state.onReturnValue,
        TimbradoObject: state.SucursalObject,
      };
    case "END":
      return { ...curShow, isShow: false };
  }
};
const Osucursal = () => {
    const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,
    SucursalObject: null,
    onReturnValue: null,
     });
     const [listSucursal, setListSucursal] = useState([]);
    const onLoadValuesSucursal = useCallback(async () => {
    try {
      const listResult = await getAllSucursal();
      setListSucursal([...listResult]);
    } catch (err) {
      console.log(err);
    }
  });
    useEffect(() => {
    onLoadValuesSucursal();
    }, []);
    const onAgregarSucursal = () => {
        dispatchReducer({
            type: "SHOW",
        });
    };
    const onCloseModal = () => {
        dispatchReducer({ type: "END" });
      };
    const onReturnSucursaldata = async (data) => {
    try {
      const result = await saveSucursal({ ...data, isNew: true });

      if (result) {
        if (result) {
          Swal.fire({
            title: "Sucursal Cargado",
            html: `Se cargo correctalmente la Sucursal`,
            icon: "success",
          });
          const listResult = await getAllSucursal();
          setListSucursal([...listResult]);
          dispatchReducer({ type: "END" });
        }
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
    };


    return (

        <Fragment>
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Sucursal  </Card.Title><Button  onClick={onAgregarSucursal}>Agregar</Button>
                </Card.Header>
                <Card.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>
                                    <strong>Codigo</strong>
                                </th>
                                <th>
                                    <strong>Nombre</strong>
                                </th>
                                <th>
                                    <strong>Direccion</strong>
                                </th>
                                <th>
                                    <strong>Telefono</strong>
                                </th>
                                <th>
                                    <strong>Opciones</strong>
                                </th>
                               
                            </tr>
                        </thead>
                        <tbody>
                            {listSucursal.map((item,index) => {
                                return (
                                    <tr key={index}>
                                        <td><h3>{item.codigo}</h3></td>
                                        <td><h3>{item.nombre}</h3></td>
                                        <td><h3>{item.direccion}</h3></td>
                                        <td><h3>{item.telefono}</h3></td>
                                        <td><h3>Editar</h3></td>
                                    </tr>
                                );
                            })}

                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Col>
           {reducer.isShow && (
            <ModalSucursal
              estado={reducer.isShow}
              onCloseModal={onCloseModal}
            />
          )}  
          {reducer.isShow && (
            <ModalSucursal
              estado={reducer.isShow}
              onCloseModal={onCloseModal}
              onReturnValue={onReturnSucursaldata}
            />
          )}  
</Fragment> 
    )
}
export default Osucursal;