import React, {
  Fragment,
  useCallback,
  useEffect,
  useReducer,
  useState,
  useMemo,
} from "react";
import { Card, Button } from "react-bootstrap";
import Swal from "sweetalert2";

import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { TailSpin } from "react-loader-spinner";
import { COLUMNS } from "./Columns";
import { GlobalFilter } from "./GlobalFilter";
//import './table.css';
import "./filtering.css";
import AgregarProveedores from "./ModalCompras/AgregarProveedores";
import { getAllProveedores, saveProveedor,deleteProveedor} from "../../../Api/Proveedores";

const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
        onReturnValue: state.onReturnValue,
      };
    case "END":
      return { ...curShow, isShow: false };
  }
};
const Proveedores = () => {
  
  const [Editdata,setEditdata]=useState([
    {
      proveedor: "",
      razonsocial: "",
      ruc: "",
      Ciudad: "",
      direccion: "",
      telefono: "",
      correo: "",
    },
  ])
  const [isnew,setisnew]=useState(true)
  const [loading, setLoading] = useState(true);
  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,
  });
  const [data, setdata] = useState([]);

  const assigmentValue = useCallback(async () => {
    try {
      const result = await getAllProveedores();
      setdata([...result]);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  });
  useEffect(() => {
    assigmentValue();
  }, []);
  const handleShow4 = () => {
    setEditdata([
      {
        proveedor: "",
        razonsocial: "",
        ruc: "",
        Ciudad: "",
        direccion: "",
        telefono: "",
        correo: "",
      },
    ])
    setisnew(true)
    dispatchReducer({ type: "SHOW"});
  };
  const onCloseModal = () => {
    dispatchReducer({ type: "END" });
  };
  const columns = useMemo(() => COLUMNS, []);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;
  const onReturnDataCliente = async (data,isNew) => {
    try {
      Swal.fire({
        title: "Cargando",
        html: "Cargando  datos", // add html attribute if you want or remove
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
      const result = await saveProveedor({ ...data, isNew });

      if (result) {
        if (result) {
          const listResult = await getAllProveedores();
          setdata([...listResult]);
          Swal.close();
          Swal.fire({
            title: "Proveedor Cargado",
            html: `Se cargo correctalmente el Proveedor`,
            icon: "success",
            confirmButtonColor: "black",
          });
          dispatchReducer({ type: "END" });
        }
      }
    } catch (err) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };

  const { globalFilter, pageIndex } = state;
  const Editar = (item) => {
    let row = data.filter(num => num.idProveedores===item);
   setEditdata(row)
   setisnew(false);
    dispatchReducer({ type: "SHOW"});
    
   
  };
  const Delete = (id) => {
    try {
      Swal.fire({
        title: "Estas seguro/a de Eliminar?",
        text: "No es Reversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "black",
        cancelButtonColor: "red",
        confirmButtonText: "Si! Eliominar",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Cargando",
            html: "Cargando  datos", // add html attribute if you want or remove
            allowOutsideClick: false,
            showCancelButton: false,
            showConfirmButton: false,
          });
          deleteProveedor(id)
          assigmentValue();
          Swal.close();
          Swal.fire({
            title: "Eliminado",
            text: "Proveedor Eliminado",
            icon: "warning",
            confirmButtonColor: "black",
          });
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Fragment>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body pb-0">
            <div className="row justify-content-between">
              <div className="col-auto">
                <Card.Title>Proveedores</Card.Title>
              </div>
              <div className="col-auto">
                <h5>
                  <Button onClick={handleShow4}>Agregar</Button>
                </h5>
              </div>
            </div>
          </div>
          <div className="chart-wrapper">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <GlobalFilter
                    filter={globalFilter}
                    setFilter={setGlobalFilter}
                  />
                  <TailSpin
                    height="30"
                    width="30"
                    color="Black"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={loading}
                  />
                  <br></br>
                  <table
                    {...getTableProps()}
                    className="table dataTable display"
                  >
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                              {column.render("Header")}
                              {column.canFilter
                                ? column.render("Filter")
                                : null}
                            </th>
                          ))}
                          <th style={{ padding: "20px" }}>Opciones</th>
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()} className="">
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()} className="p-2">
                                
                                  {cell.render("Cell")}{" "}
                                </td>
                              );
                            })}
                            <td  className="d-flex ml-5" >
                              
                                <Button
                                
                                  onClick={() => Editar(row.values.idProveedores)}
                                  className="fas fa-pencil-alt"
                                  size="sm"
                                ></Button>
                             
                              
                                <Button
                                  onClick={() => Delete(row.values.idProveedores)}
                                  className="fa fa-trash "
                                  variant="danger"
                                  size="sm"
                                ></Button>
                              
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-between">
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                      {""}
                    </span>
                    <span className="table-index">
                      Go to page :{" "}
                      <input
                        type="number"
                        className="ml-2"
                        defaultValue={pageIndex + 1}
                        onChange={(e) => {
                          const pageNumber = e.target.value
                            ? Number(e.target.value) - 1
                            : 0;
                          gotoPage(pageNumber);
                        }}
                      />
                    </span>
                  </div>
                  <div className="text-center mb-3">
                    <div className="filter-pagination  mt-3">
                      <button
                        className=" previous-button"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                      >
                        {"<<"}
                      </button>

                      <button
                        className="previous-button"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                      >
                        Previous
                      </button>
                      <button
                        className="next-button"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                      >
                        Next
                      </button>
                      <button
                        className=" next-button"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                      >
                        {">>"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {reducer.isShow && (
        <AgregarProveedores
          estado={reducer.isShow}
          onCloseModal={onCloseModal}
          onReturnValue={onReturnDataCliente}
          Editdata={Editdata}
          isnew={isnew}
        />
      )}
    </Fragment>
  );
};
export default Proveedores;
