import {  useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import Swal from 'sweetalert2'

const ModalDeposito = (props) => {
    const {
        estado,
        onCloseModal,
        onReturnValue,
        listFormadepago,
        id
    } = props;
    console.log(id)
    const [formData1, setValue1] = useState([]);
    const [formData2, setValue2] = useState([]);
    const [formData3, setValue3] = useState([]);

    const handleSubmit2 = (event) => {
        event.preventDefault();
        formData1.valueUpdate=formData1.valueUpdate? formData1.valueUpdate: "";
        if ( formData1.valueUpdate=="")
        {
            Swal.fire({
                icon: 'error',
                title: 'Metodo de pago',
                text: 'Selecciones Metodo de pago',
                
              })
        }else
    {
        onReturnValue(formData1,formData2,formData3);
    }
       
       
    }
    return (
        <>

            <Modal
                show={estado}
                onHide={onCloseModal}

            >

                <Modal.Header closeButton>
                    <Modal.Title>Depositar</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form onSubmit={handleSubmit2}
                        id="auFORM">
                      
                        <div className="form-group mb-2 row">
                            <label className="col-form-label">
                            Forma de Pago
                                <span className="text-danger">*</span>
                            </label>
                            <div className="">
                            <Select
                                options={listFormadepago}
                                onChange={(e) => {
                                setValue1({
                                    ...formData1,
                                    valueUpdate: e.value,
                                    FileUpdate:"FORMA_PAGO",
                                    idFactura:id
                                });
                              }}
                  />
                            </div>
                        </div>
                        <div className="form-group mb-2 row">
                            <label className="col-form-label">
                              Numero de Comprobante
                                <span className="text-danger">*</span>
                            </label>
                            <div className="">
                                <input
                                    type="text"
                                    maxLength={200}
                                    className="form-control"
                                    required
                                    onChange={e => { setValue2({
                                         ...formData2, 
                                         valueUpdate: e.target.value,
                                         FileUpdate:"NUMERO_COMPROBANTE",
                                         idFactura:id
                                         }) }}
                                  

                                />
                            </div>
                        </div>
                        <div className="form-group mb-2 row">
                            <label className="col-form-label">
                              OBs.
                                <span className="text-danger">*</span>
                            </label>
                            <div className="">
                                <textarea
                                    className="form-control"
                                    maxLength={200}
                                    onChange={e => { setValue3({
                                         ...formData3, 
                                         valueUpdate: e.target.value,
                                         FileUpdate:"OBS",
                                         idFactura:id
                                         }) }}
                                />
                            </div>
                        </div>
                        <br>
                        </br>
                        <Button variant="danger" onClick={onCloseModal}>
                        Cerrar
                    </Button>
                    <Button type="submit" >
                        Guardar
                    </Button>
                    </form>





                </Modal.Body>
               

            </Modal>


        </>
    );
}

export default ModalDeposito;