import {  useState } from "react";
import { Button, Modal } from "react-bootstrap";


const ModalPDV = (props) => {
    const {
        estado,
        onCloseModal,
        onReturnValue
    } = props;

    const [formData3, setValue] = useState([]);



    const handleSubmit2 = (event) => {
        event.preventDefault();
        console.log(formData3);
        onReturnValue(formData3);
    }
    return (
        <>

            <Modal
                show={estado}
                onHide={onCloseModal}

            >

                <Modal.Header closeButton>
                    <Modal.Title>Agregar Punto de Venta</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form onSubmit={handleSubmit2}
                       >
                      
                        <div className="form-group mb-2 row">
                            <label className="col-form-label">
                                codigo
                                <span className="text-danger">*</span>
                            </label>
                            <div className="">
                                <input
                                    type="number"
                                    className="form-control"
                                    required
                                    onChange={e => { setValue({ ...formData3, codigo: e.target.value }) }}
                                  

                                />
                            </div>
                        </div>
                        <div className="form-group mb-2 row">
                            <label className="col-form-label">
                                Punto de Venta
                                <span className="text-danger">*</span>
                            </label>
                            <div className="">
                                <input
                                    type="text"
                                    className="form-control"
                                    required
                                    onChange={e => { setValue({ ...formData3, nombre: e.target.value }) }}
                                  

                                />
                            </div>
                        </div>
                      
                        <Button variant="danger" onClick={onCloseModal}>
                        Cerrar
                    </Button>
                    <Button type="submit">
                        Guardar
                    </Button>
                        

                    </form>





                </Modal.Body>
               

            </Modal>


        </>
    );
}

export default ModalPDV;