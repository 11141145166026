import { CALL_API2 } from "../Utils/const";
import { getAuthToken } from "../context/auth-context";

export async function getAllProveedores() {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/proveedores`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await result.json();
  
  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}
export async function saveProveedor(ProveedorObject) {
console.log(ProveedorObject)
    const { isNew, idProveedor } = ProveedorObject;
    const token = getAuthToken();
    let urlSend = `${CALL_API2}/proveedor`;
  
    let method = "POST";
  
    if (ProveedorObject.isNew) delete ProveedorObject.isNew;
    if (ProveedorObject.idProveedor) delete ProveedorObject.idForma;
  
    if (!isNew) {
      urlSend = `${urlSend}/${idProveedor}`;
      method = "PUT";
    }
  
    const result = await fetch(urlSend, {
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ProveedorObject),
    });
  
    const data = await result.json();
  
    if (!result.ok) {
      throw new Error(data.message);
    }
  
    return data.isSaved;
  }

export async function getProveedorById(idProveedores) {
  
  const token = getAuthToken();
  
  const response = await fetch(`${CALL_API2}/proveedor/${idProveedores}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  
  const data = await response.json();
 
  if (!response.ok) {
    throw new Error(data.message);
  }
  return data.result;
}
/*
export async function getSearchClient(sqlQuery) {
  const token = getAuthToken();
  const response = await fetch(
    `${CALL_API2}/search-cliente?sqlQuery=${sqlQuery}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }
  return data.result;
}

*/

export async function deleteProveedor(idProveedor) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API2}/Proveedor/${idProveedor}`, {
    headers: { Authorization: `Bearer ${token}` },
    method: "DELETE",
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }
  return data.isDeleted;
}