import React, { useEffect, useState, useCallback,useReducer,useRef } from "react";
import { useLocation } from "react-router-dom";
import { Button, ButtonGroup } from "react-bootstrap";
import { getFacturaById,anularFactura, getDetalleFacturaId,updateFactura } from "../../../../Api/facturacion"
import { getAllFormaPago } from "../../../../Api/formaPago"
import ModalDeposito from "./ModalFichaFactura/ModalDeposito"
import ModalModFecha from "./ModalFichaFactura/ModalModFecha"
import Table from 'react-bootstrap/Table';
import Swal from "sweetalert2";
import { TailSpin } from  'react-loader-spinner'
import { getUserCredentials } from "../../../../Api/usuario";
import genPDF from "../../pdfWriter";
const numberToTextss = require('../../TextoNumero')
var myStyle = {
    height: '8%',
}
var myStyle2 = {
    borderTop: '1px solid #222',

    height: '10%',
}
var myStyle3 = {
    borderTop: '1px solid #222',

    height: '68%',
}

var myStyle5 = {
    borderTop: '1px solid #222',
    height: '4%',
}
var myStyle6 = {


    height: '4%',
}

var myStyleex = {
    borderTop: '1px solid #222',

    height: '2%',
}
var myStyleex2 = {
    borderTop: '1px solid #222',

    height: '2%',
}
var myStylh1 = {
    borderRight: '1px solid #222',
    width: '6%',
    fontSize: 8
}
var myStylh2 = {
    borderRight: '1px solid #222',
    width: '8%',
    fontSize: 8
}
var myStylh3 = {
    borderRight: '1px solid #222',
    width: '40%',
    fontSize: 8
}
var myStylh4 = {
    borderRight: '1px solid #222',
    width: '10%',
    fontSize: 8
}
var myStylh5 = {
    borderRight: '1px solid #222',
    width: '7%',
    fontSize: 8
}
var myStylh6 = {
    borderRight: '1px solid #222',
    width: '14%',
    fontSize: 8
}
var myStylh7 = {

    width: '14%',
    fontSize: 8
}
var myStyleventa = {
    borderRight: '1px solid #222',
    width: '64%',
    fontSize: 8
}
var myStyleventa2 = {

    width: '37%',
    fontSize: 8
}
var myStylSubtotal = {
    borderRight: '1px solid #222',
    width: '64%',
    fontSize: 8
}
var myStylSubtotal2 = {
    borderRight: '1px solid #222',
    width: '8%',
    fontSize: 12
}
var myStylSubtotal3 = {
    borderRight: '1px solid #222',
    width: '14%',
    fontSize: 12
}
var myStylSubtotal4 = {

    width: '14%',
    fontSize: 12
}
var myStyltotal = {
    borderRight: '1px solid #222',
    width: '86%',
    fontSize: 8
}
var myStyltotal2 = {

    width: '14%',
    fontSize: 12
}
var myStylh11 = {

    width: '30px',
    fontSize: 12
}
var myStylh12 = {

    width: '39px',
    fontSize: 12
}
var myStylh13 = {

    width: '184px',
    fontSize: 12
}
var myStylh14 = {

    width: '48px',
    fontSize: 12
}
var myStylh15 = {

    width: '36px',
    fontSize: 12
}
var myStylh16 = {

    width: '66px',
    fontSize: 12
}
var myStylh17 = {
    width: '66px',
    fontSize: 12
}
var myStylex1 = {
    borderRight: '1px solid #222',
    width: '50%',
    fontSize: 12
}
var myStylex2 = {
    width: '50%',
    fontSize: 30,
    color:'Red'
}
const showReducer = (curShow, state) => {
    switch (state.type) {
      case "SHOW":
        return {
          isShow: true,
          onReturnValue: state.onReturnValue,
          ListFDP: state.ListFDP,
        };
        case "SHOW2":
            return {
                isShow: false,
              isShow2: true,
              onReturnValue: state.onReturnValue,
            };
      case "END":
        return { ...curShow, isShow: false, isShow2: false };
  
    }
  };
const FichaFactura = () => {
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);
    const [reducer, dispatchReducer] = useReducer(showReducer, {
        isShow: false,
        isShow2:false,
        ListFDP: null,
        onReturnValue: null,
      });
      const onAgregarDeposito = () => {
        dispatchReducer({
          type: "SHOW",
        });
      };
      const onModFechaVencimiento = () => {
        dispatchReducer({
            type: "SHOW2",
          });
      };
      const onImprimir = () => {
       const datosfactura = datosfacturas[0]
       const isCredito=!contado
      
       let TextoNumber= numberToTextss.NumerosALetras(datosfactura.totalFactura)+" "+"Guaranies--"
       const sum1 = detallefactura.reduce((previous, current) => {
        return previous +  Number(current.cinco)
        ; // sumar el valor de una propiedad
      }, 0);
      const sum2 = detallefactura.reduce((previous, current) => {
        return previous +  Number(current.diez)
        ; // sumar el valor de una propiedad
      }, 0);
       genPDF({ datosfactura, detallefactura,isCredito,TextoNumber,sum1,sum2})
      };
      const onCloseModal = () => {
        dispatchReducer({ type: "END" });
      };
      const onReturnDeposito = async (data1,data2,data3) => {
        
        try {
            const result = await  updateFactura(data1);
            const result2 = await  updateFactura(data2);
            const result3 = await  updateFactura(data3);
            if (result && result2 && result3) {
              if (result && result2) {
                Swal.fire({
                  title: "Deposito Realizado",
                  html: `Actualizado Correctamente`,
                  icon: "success",
                });
            const listResult3 = await getFacturaById(datosfacturas[0].idFactura);
             const listResult4 = await getDetalleFacturaId(datosfacturas[0].idFactura);
            setFactura(listResult3);
            setDetalle(listResult4);
            Ncomprobante.current=data2.valueUpdate
            btndepositar.current=true
                dispatchReducer({ type: "END" });
              }
            }
          } catch (err) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `${err}`,
            });
          }
       
      };
    const location = useLocation();
    const id = location.state?.id;
    const [id2, setid2] = useState(id);
    const [datosfacturas, setFactura] = useState([]);
    const [detallefactura, setDetalle] = useState([]);
    const [contado, setContado] = useState();
    const [credito, setCredito] = useState();
    const btnanular=useRef(false);
    const btndepositar=useRef(false);
    const btnvencimiento=useRef(false);
    const FacAnulado=useRef("");
    const Ncomprobante=useRef("NO");
    const [listFormadepago, setListFormadepago] = useState([]);
    const numberToTextss = require('../../TextoNumero')

    const assigmentValue = useCallback(async () => {
        try {
            const listResult = await getFacturaById(id2);
            const listResult2 = await getDetalleFacturaId(id2);
            const listResut3 = await getAllFormaPago()
            const result2 = await  getUserCredentials();
            setUserData(result2)
            const resultMapeado = listResut3.map((item) => {
                    return { label: item.nombre, value: item.nombre };
              });
            let resulting = resultMapeado.filter(item => item.label!="Efectivo")
            setListFormadepago(resulting);
            
            if (listResult[0].anulado.data==1)
            {
                FacAnulado.current="ANULADO"
                btnanular.current=true
                btndepositar.current=true
                btnvencimiento.current=true
            }
            if(listResult[0].numerodecomprobante!="NO")
            {
                btndepositar.current=true
            }
            Ncomprobante.current=listResult[0].numerodecomprobante
            if (listResult[0].tipoFactura == "Contado") {
                setContado(true)
                setCredito(false)
            } else {
                setContado(false)
                setCredito(true)
            }
            setFactura(listResult);
            setDetalle(listResult2);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }, [id]);
    useEffect(() => {
        assigmentValue();
    }, [assigmentValue]);


    const Anular = () =>  {
    try{
        Swal.fire({
            title: 'Estas seguro/a de Anular?',
            text: "No es Reversible!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'black',
            cancelButtonColor: 'red',
            confirmButtonText: 'Si! anular'
          }).then((result) => {
            if (result.isConfirmed) {
                (async () => {
                    anularFactura(id)
                    })()
                    assigmentValue()
                    FacAnulado.current="ANULADO"
                    btnanular.current=true
                    btndepositar.current=true
                    btnvencimiento.current=true
                    Ncomprobante.current='NO'
                    Swal.fire({
                        title: 'Anulado',
                        text: "Factura Anulada",
                        icon: 'warning',
                       
                        confirmButtonColor: 'black',
                       
                      })
              
            }
          })

        }
        catch (err) {
            console.log(err);
        }
    }
    const onReturnVencimiento  = async (data) => {
        console.log(data)
        try {
            const result = await  updateFactura(data);
           
            
            if (result ) {
              if (result ) {
                Swal.fire({
                  title: "Vencimiento Actualizado",
                  html: `Actualizado Correctamente`,
                  icon: "success",
                });
              const listResult3 = await getFacturaById(id);
             const listResult4 = await getDetalleFacturaId(id);
            setFactura(listResult3);
            setDetalle(listResult4);
                dispatchReducer({ type: "END" });
              }
            }
          } catch (err) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `${err}`,
            });
          }

    }


    return (
        <>
         <TailSpin
                height="80"
                width="80"
                color="Black"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={loading}
                />
            {datosfacturas.map((datosfactura, index2) => {
                return (
                    <div key={index2}>

                        <div className="row">
                            <div className="col-xl-5 col-xxl-6 mb-4" >
                                <div style={
                                    {
                                        border: '1px solid black',
                                        width: '12.5cm',
                                        height: '16.45cm',
                                        background: 'white'
                                    }
                                }
                                >
                                    <div className='d-flex justify-content-between mt-1 ml-1' style={myStyle}>
                                        <div className='row' style={myStylex1}>
                                        <span style={{ fontSize: 12 }}>Timbrado: {datosfactura.timbrado} </span>
                                        <span style={{ fontSize: 12 }}>Numero Factura: {datosfactura.pf1}-{datosfactura.pf2}-{datosfactura.numerofactura} </span>
            
                                        </div>
                                        <div className='row' style={myStylex2}>
                                        <span>{FacAnulado.current}</span>
                                        </div>
                        
                                       
                                    </div>
                                    <div style={myStyle2}>


                                        <div className='d-flex justify-content-between mt-1 ml-1'>
                                            <span style={{ fontSize: 10 }}>Fecha Emision: {datosfactura.fechafactura} </span>
                                            <span style={{ fontSize: 10 }}>Cond. de venta: contado<input type="checkbox" defaultChecked={contado} /> credito<input type="checkbox" defaultChecked={credito} /></span>
                                        </div>
                                        <div className="d-flex flex-row ">
                                            <span className="ml-1" style={{ fontSize: 10 }}>Nombre o Razon Social: {datosfactura.razonsocial}</span>
                                        </div>
                                        <div className='d-flex justify-content-between mt-1 ml-1'>
                                            <span style={{ fontSize: 10 }}>R.U.C o C.I No: {datosfactura.ruc}</span>
                                            <span style={{ fontSize: 8 }}>Direccion:{datosfactura.direccion}</span>
                                            <span style={{ fontSize: 8 }}>Tel:{datosfactura.telefono}</span>
                                            <div></div>
                                        </div>
                                    </div>
                                    <div style={myStyleex2} className="d-flex align-items-stretch">
                                        <div className='ml-1' style={myStyleventa}></div>
                                        <div className='d-flex justify-content-center' style={myStyleventa2}><span>Venta</span></div>

                                    </div>
                                    <div style={myStyleex} className="d-flex align-items-stretch">
                                        <div className='ml-1' style={myStylh1}>cant.</div>
                                        <div className='ml-1' style={myStylh2}>ArtCod.</div>
                                        <div className='ml-1' style={myStylh3}>Descripcion</div>
                                        <div className='ml-1' style={myStylh4}>P. Unitario</div>
                                        <div className='ml-1' style={myStylh5}>exentas</div>
                                        <div className='ml-1' style={myStylh6}>5%</div>
                                        <div className='ml-1' style={myStylh7}>10%</div>
                                    </div>
                                    <table style={myStyle3} className="d-flex align-items-stretch">

                                        <tbody className='' style={{ height: '100%', width: '100%' }}>
                                            {detallefactura.map((detallefactura, index) => {
                                                return (
                                                    <tr key={index} style={{ height: '100%', width: '100%' }}>
                                                        <td style={myStylh11}><span className='d-flex justify-content-center' > {detallefactura.cantidad} </span></td>
                                                        <td style={myStylh12}><span className='d-flex justify-content-center' > {detallefactura.artcod} </span></td>
                                                        <td style={myStylh13} ><span> {detallefactura.producto} </span></td>
                                                        <td style={myStylh14}><span> {detallefactura.precio} </span></td>
                                                        <td style={myStylh15}><span>{detallefactura.exenta}</span></td>
                                                        <td style={myStylh16}><span> {detallefactura.cinco} </span></td>
                                                        <td style={myStylh17}><span> {detallefactura.diez} </span></td>
                                                    </tr>
                                                );
                                            })}

                                        </tbody>
                                    </table>

                                    <div style={myStyleex2} className="d-flex align-items-stretch">

                                        <div className='d-flex justify-content-left' style={myStylSubtotal}>Sub Total</div>
                                        <div className='d-flex justify-content-center' style={myStylSubtotal2}><span></span></div>
                                        <div className='d-flex justify-content-center' style={myStylSubtotal3}><span>{datosfactura.Subtotal5}</span></div>
                                        <div className='d-flex justify-content-center' style={myStylSubtotal4}><span>{datosfactura.Subtotal10}</span></div>


                                    </div>
                                    <div style={myStyle5} className="d-flex align-items-stretch">
                                        <div className='d-flex justify-content-left' style={myStyltotal}>Total a pagar(en letas)  <span style={{ fontSize: 9}} >{numberToTextss.NumerosALetras(datosfactura.totalFactura)} Guaranies---------</span> </div>
                                        <div className='d-flex justify-content-center' style={myStyltotal2}>{datosfactura.totalFactura}</div>
                                    </div>
                                    <div style={myStyle6} >
                                        <div style={myStyle5} className='d-flex justify-content-around '>
                                            <div className='d-flex justify-content-left mt-1' style={myStyltotal}>LIQUIDACIOON DEL IVA (5%) <span> {datosfactura.gravado05}</span></div>
                                            <div className='d-flex justify-content-center mt-1' style={myStyltotal}>(10%) {datosfactura.gravado10}</div>
                                            <div className='d-flex justify-content-center mt-1' style={myStyltotal}>Total Iva: {datosfactura.ivamonto}</div>

                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                            <div className="col-xl-5 col-xxl-5">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">Datos Extras</h4>
                                                {userData.IdUsuario=== 103 && (
                                                <ButtonGroup size="sm" aria-label="Basic example">
                                                    <Button disabled={btnanular.current}onClick={Anular} variant="danger">Anular</Button>
                                                    <Button disabled={btndepositar.current}onClick={onAgregarDeposito} >Depositar</Button>
                                                    <Button disabled={btnvencimiento.current} onClick={onModFechaVencimiento}>Fecha Vencimiento</Button>
                                                    <Button  onClick={onImprimir}>Imprimir</Button>
                                                </ButtonGroup>
                                                )}
                                                {reducer.isShow && (
                                                <ModalDeposito
                                                estado={reducer.isShow}
                                                onCloseModal={onCloseModal}
                                                listFormadepago={listFormadepago}
                                                onReturnValue={onReturnDeposito}
                                                id={datosfacturas[0].idFactura}
                                                />
                                                  )}  
                                                {reducer.isShow2 && (
                                                <ModalModFecha
                                                estado={reducer.isShow2}
                                                onCloseModal={onCloseModal}
                                                onReturnValue={onReturnVencimiento}
                                                id={id}
                                                />
                                                  )}  
                                                  
                                            </div>
                                            <div className="card-body p-4">
                                                <span> Forma de Pago:  {datosfactura.formadepago}</span>
                                                <br></br>
                                                <span>
                                                   Numero de Comprobante : {Ncomprobante.current}
                                                </span>
                                                <br></br>
                                                <span>
                                                  Tipo de Factura : {datosfactura.tipoFactura}
                                                </span>
                                                <br></br>
                                                <span>Fecha Vencimiento : {datosfactura.fechapagovencimiento} </span>
                                                <br></br>
                                                <span>Obs : {datosfactura.obs} </span>
                                                <br></br>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">datos extra Detalle factura</h4>
                      </div>
                      <div className="card-body p-4 " >
                        <Table responsive className="primary-table-bordered" >
                          <thead className="thead-primary">
                            <tr>
                              <th style={{fontSize: 10}}scope="col">Producto/Servicio</th>
                              <th  style={{fontSize: 10}} scope="col">Precio</th>
                              <th style={{fontSize: 10}}  scope="col">Precio Des.</th>
                              <th style={{fontSize: 10}} scope="col">Monto Des.</th>
                              <th style={{fontSize: 10}} scope="col">des %</th>
                            </tr>
                          </thead>
                          <tbody>
                            {detallefactura.map((items3, index) => (
                              <tr key={index}>
                                <td>{items3.producto}</td>
                                <td>{items3.preciosindescuento}</td>
                                <td>{items3.precio}</td>
                                <td>{items3.montodescuento}</td>
                                <td>{items3.descuento}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                );
               
            })}
            
            
        </>
    )
}
export default FichaFactura;