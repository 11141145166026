import {  useState, Fragment } from "react";
import { Button, Modal } from "react-bootstrap";





const ModalCliente = (props) => {
    const {
        estado,
        onCloseModal,
        
    } = props;
    
    const [formData3, setValue] = useState([]);

   

    const handleSubmit2 = (event) => {
        event.preventDefault();
        console.log(formData3);
    }
    return (
        <>
         <Fragment>
            <Modal
                show={estado}
                onHide={onCloseModal}

            >

                <Modal.Header closeButton>
                    <Modal.Title>Agregar Costo</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form onSubmit={handleSubmit2}
                        id="auFORM">
                      
                        <div className="form-group mb-2 row">
                            <label className="col-form-label">
                              Codigo
                                <span className="text-danger">*</span>
                            </label>
                            <div className="">
                                <input
                                    type="text"
                                    className="form-control"
                                    required
                                    onChange={e => { setValue({ ...formData3, preciousuario: e.target.value }) }}
                                  

                                />
                            </div>
                        </div>
                        <div className="form-group mb-2 row">
                            <label className="col-form-label">
                              Servicio/Producto
                                <span className="text-danger">*</span>
                            </label>
                            <div className="">
                                <input
                                    type="text"
                                    className="form-control"
                                    required
                                    onChange={e => { setValue({ ...formData3, preciousuario: e.target.value }) }}
                                  

                                />
                            </div>
                        </div>
                        <div className="form-group mb-2 row">
                            <label className="col-form-label">
                              Costo
                                <span className="text-danger">*</span>
                            </label>
                            <div className="">
                                <input
                                    type="text"
                                    className="form-control"
                                    required
                                    onChange={e => { setValue({ ...formData3, preciousuario: e.target.value }) }}
                                  

                                />
                            </div>
                        </div>
                  
                       
                        

                    </form>





                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={onCloseModal}>
                        Cerrar
                    </Button>
                    <Button type="submit" form="auFORM">
                        Guardar
                    </Button>
                </Modal.Footer>

            </Modal>
            </Fragment>

        </>
        
    );
}

export default ModalCliente;