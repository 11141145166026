
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import genPDF from "./pdfWriter"
var myStyle = {
    borderRight: '1px solid #222',
    width: '50%',
    height: '8%',
}
var myStyle2 = {
    borderTop: '1px solid #222',

    height: '8%',
}
var myStyle3 = {
    borderTop: '1px solid #222',

    height: '68%',
}

var myStyle5 = {
    borderTop: '1px solid #222',
    height: '4%',
}
var myStyle6 = {


    height: '4%',
}
var myStyle7 = {
    borderTop: '1px solid #222',

    height: '2%',
}
var myStyleex = {
    borderTop: '1px solid #222',

    height: '2%',
}
var myStyleex2 = {
    borderTop: '1px solid #222',

    height: '2%',
}
var myStylh1 = {
    borderRight: '1px solid #222',
    width: '6%',
    fontSize: 8
}
var myStylh2 = {
    borderRight: '1px solid #222',
    width: '8%',
    fontSize: 8
}
var myStylh3 = {
    borderRight: '1px solid #222',
    width: '40%',
    fontSize: 8
}
var myStylh4 = {
    borderRight: '1px solid #222',
    width: '10%',
    fontSize: 8
}
var myStylh5 = {
    borderRight: '1px solid #222',
    width: '7%',
    fontSize: 8
}
var myStylh6 = {
    borderRight: '1px solid #222',
    width: '14%',
    fontSize: 8
}
var myStylh7 = {

    width: '14%',
    fontSize: 8
}
var myStyleventa = {
    borderRight: '1px solid #222',
    width: '64%',
    fontSize: 8
}
var myStyleventa2 = {

    width: '37%',
    fontSize: 8
}
var myStylSubtotal = {
    borderRight: '1px solid #222',
    width: '64%',
    fontSize: 8
}
var myStylSubtotal2 = {
    borderRight: '1px solid #222',
    width: '8%',
    fontSize: 8
}
var myStylSubtotal3 = {
    borderRight: '1px solid #222',
    width: '14%',
    fontSize: 8
}
var myStylSubtotal4 = {

    width: '14%',
    fontSize: 8
}
var myStyltotal = {
    borderRight: '1px solid #222',
    width: '86%',
    fontSize: 8
}
var myStyltotal2 = {

    width: '14%',
    fontSize: 12
}
var myStylh11 = {
   
    width: '30px',
    fontSize: 12
}
var myStylh12 = {
    
    width: '39px',
    fontSize: 12
}
var myStylh13 = {
   
    width: '184px',
    fontSize: 12
}
var myStylh14 = {
    
    width: '48px',
    fontSize: 12
}
var myStylh15 = {
   
    width: '36px',
    fontSize: 12
}
var myStylh16 = {
   
    width: '66px',
    fontSize: 12
}
var myStylh17 = {
    width: '66px',
    fontSize: 12
}
const ModalPreview = (props) => {
    const { estado, onCloseModal, onReturnValue2, datosfactura, detallefactura,credito,contado } = props;
    const numberToTextss = require('./TextoNumero')
    const [not,SetNot]=useState(false)
    const handleclick = () => {
       SetNot(true)
        let isCredito=false
        if (credito) isCredito=credito
        let TextoNumber= numberToTextss.NumerosALetras(datosfactura.totalmonto)+" "+"Guaranies--"
        onReturnValue2({datosfactura,detallefactura,isCredito,TextoNumber});
    };
    
    return (
        <>
            <Modal show={estado} onHide={onCloseModal} size='l'>
                <Modal.Header closeButton>
                    <Modal.Title>Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={
                        {
                            border: '1px solid black',
                            width: '11.5cm',
                            height: '16.45cm'
                        }
                    }
                    >
                         <div className='mt-2' style={myStyle}>
                                <span style={{ fontSize: 12 }}>Timbrado: {datosfactura.timbrado} </span>
                                <br></br>
                                <span style={{ fontSize: 12}}>Numero Factura: {datosfactura.pf1}-{datosfactura.pf2}-{datosfactura.numeroActual.current} </span>
                                </div>
                        <div style={myStyle2}>
                            <div className='d-flex justify-content-between mt-1 ml-1'>
                                <span style={{ fontSize: 8 }}>Fecha Emision: {new Date().toLocaleDateString()} </span>
                                <span style={{ fontSize: 8 }}>Cond. de venta: contado<input type="checkbox"   defaultChecked={contado}/> credito<input type="checkbox" defaultChecked={credito}/></span>
                            </div>
                            <div className="d-flex flex-row ">
                                <span className="ml-1" style={{ fontSize: 8 }}>Nombre o Razon Social: {datosfactura.razonsocial}</span>
                            </div>
                            <div className='d-flex justify-content-between mt-1 ml-1'>
                                <span style={{ fontSize: 8 }}>R.U.C o C.I No: {datosfactura.ruc}</span>
                                <span style={{ fontSize: 8 }}>Direccion:{datosfactura.direccion}</span>
                                <span style={{ fontSize: 8 }}>Tel:{datosfactura.telefono}<div ></div></span>
                                <div></div>
                            </div>
                        </div>
                        <div style={myStyleex2} className="d-flex align-items-stretch">
                            <div className='ml-1' style={myStyleventa}></div>
                            <div className='d-flex justify-content-center' style={myStyleventa2}><span>Venta</span></div>

                        </div>
                        <div style={myStyleex} className="d-flex align-items-stretch">
                            <div className='ml-1' style={myStylh1}>cant.</div>
                            <div className='ml-1' style={myStylh2}>ArtCod.</div>
                            <div className='ml-1' style={myStylh3}>Descripcion</div>
                            <div className='ml-1' style={myStylh4}>P. Unitario</div>
                            <div className='ml-1' style={myStylh5}>exentas</div>
                            <div className='ml-1' style={myStylh6}>5%</div>
                            <div className='ml-1' style={myStylh7}>10%</div>
                        </div>
                        <table style={myStyle3} className="d-flex align-items-stretch">
                            <tbody className='' style={{ height:'100%',width:'100%' }}>
                                {detallefactura.map((detallefactura,index) => {
                                    return (
                                    
                                        <tr key={index} style={{ height:'100%',width:'100%'}}>
                                            <td   style={myStylh11}><span className='d-flex justify-content-center' > {detallefactura.cantidad} </span></td>
                                            <td  style={myStylh12}><span className='d-flex justify-content-center' > {detallefactura.artcod} </span></td>
                                            <td style={myStylh13} ><span> {detallefactura.producto} </span></td>
                                            <td  style={myStylh14}><span> {detallefactura.precio} </span></td>
                                            <td  style={myStylh15}><span>{detallefactura.exenta}</span></td>
                                            <td style={myStylh16}><span> {Math.round(detallefactura.cinco)} </span></td>
                                            <td style={myStylh17}><span> {Math.round(detallefactura.diez)} </span></td>
                                        </tr>
                                    );
                                })}

                            </tbody>
                        </table>

                        <div style={myStyleex2} className="d-flex align-items-stretch">

                            <div className='d-flex justify-content-left' style={myStylSubtotal}>Sub Total</div>
                            <div className='d-flex justify-content-center' style={myStylSubtotal2}><span></span></div>
                            <div className='d-flex justify-content-center' style={myStylSubtotal3}><span>{datosfactura.Subtotal5}</span></div>
                            <div className='d-flex justify-content-center' style={myStylSubtotal4}><span>{datosfactura.Subtotal10}</span></div>
                        </div>
                        <div style={myStyle5} className="d-flex align-items-stretch">
                            <div className='d-flex justify-content-left' style={myStyltotal}>Total a pagar(en letas)    {numberToTextss.NumerosALetras(datosfactura.totalmonto)} Guaranies---------</div>
                            <div className='d-flex justify-content-center' style={myStyltotal2}>{datosfactura.totalmonto}</div>
                        </div>
                        <div style={myStyle6}>
                            <div style={myStyle5} className='d-flex justify-content-around'>
                                <div className='d-flex justify-content-left' style={myStyltotal}>LIQUIDACIOON DEL IVA (5%) {datosfactura.iva5}</div>
                                <div className='d-flex justify-content-center' style={myStyltotal}>(10%) {datosfactura.iva10}</div>
                                <div className='d-flex justify-content-center' style={myStyltotal}>Total Iva: {datosfactura.ivatotal}</div>

                            </div>
                        </div>
                        <div style={myStyle7}>

                        </div>
                    </div>
                    <br>
</br>
                    <Button onClick={handleclick} disabled={not}>Guardar & Imprimir</Button>
                   
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalPreview;

