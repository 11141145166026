import React, {
  useReducer,
  useEffect, useState, useCallback
} from "react";
import { useLocation,useNavigate} from "react-router-dom";
import {
  Table,
  Button,
  ButtonGroup
} from "react-bootstrap";
import ModalAgregarClientes from "../../sis/ModalFacturacion/ModalAgregarCliente"
import { getClienteById , deleteClient,saveCliente} from "../../../../Api/cliente"
import { getFacturaByClienteId, getFacturaCredByClienteId} from "../../../../Api/facturacion"
import { TailSpin } from  'react-loader-spinner'
import Swal from "sweetalert2";
const showReducer = (curShow, state) => {

  
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
      };
    case "SHOW2":
      return {
        isShow2: true,
      };
    case "END":
      return { ...curShow, isShow: false, isShow2: false };
  }
};
const FichaCliente = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const id = location.state?.id;
  const [items, setItems] = useState([]);
  const [items2, setItems2] = useState([]);
  const [items3, setItems3] = useState([]);

  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,

  });
  const Editar = () => {

    dispatchReducer({
      type: "SHOW",
    });
  };
  const onReturnValue = async (data) => {
    try {
      Swal.fire({
        title: 'Cargando',
        html: 'Cargando  datos',// add html attribute if you want or remove
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
       
    });
      const result = await saveCliente({ ...data, isNew: false,idCliente:id});

      if (result) {
        if (result) {
          assigmentValue()
          Swal.close();
          Swal.fire({
            title: "Cliente Cargado",
            html: `Se cargo correctalmente el cliente`,
            icon: "success",
            confirmButtonColor: 'black'
          });
          dispatchReducer({ type: "END" });
        }
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };


  const onCloseModal = () => {
    dispatchReducer({ type: "END" });
  };
  const Eliminar = () =>  {
    
    try{
        Swal.fire({
            title: 'Estas seguro/a de Eliminar?',
            text: "No es Reversible!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'black',
            cancelButtonColor: 'red',
            confirmButtonText: 'Si! Eliominar'
          }).then((result) => {
            if (result.isConfirmed) {
                
                  deleteClient(id)

                    Swal.fire({
                        title: 'Eliminado',
                        text: "Cliente Eliminado",
                        icon: 'warning',
                        confirmButtonColor: 'black',
                      })
                      navigate("/Clientes")
             
            }
          })

        }
        catch (err) {
            console.log(err);
        }
    }
  const assigmentValue = useCallback(async () => {
    try {
      const result = await getClienteById(id);
      const result2 = await getFacturaByClienteId(id);
      const result3 = await getFacturaCredByClienteId(id);



      setItems(result);
      setItems2(result2);
      setItems3(result3);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [id]);
  useEffect(() => {
    assigmentValue();
  }, [assigmentValue]);
  return (
    <>
     <TailSpin
                height="80"
                width="80"
                color="Black"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={loading}
                />
      {items.map((item, index2) => {
        return (
          <div key={index2}>
            <div className="row">
              <div className="col-xl-4 col-xxl-4">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">{item.nombre}</h4><ButtonGroup aria-label="Basic example">
                          <Button onClick={Editar}variant="primary">Editar</Button>
                          <Button onClick={Eliminar}variant="danger">Eliminar</Button>
                        </ButtonGroup>

                      </div>
                      <div className="card-body p-4">
                        <span>Razon Social :  {item.razonsocial}</span>
                        <br></br>
                        <span>
                          Ruc : {item.ruc}
                        </span>
                        <br></br>
                        <span>Telefono : {item.telefono} </span>
                        <br></br>
                        <span>Correo : {item.correo}</span>
                        <br></br>
                        <span>Ciudad : {item.ciudad}</span>
                        <br></br>
                        <span>Direccion : {item.direccion}</span>

                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div className="col-xl-8 col-xxl-8">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Historial Facturas a Credito</h4>
                      </div>
                      <div className="card-body p-4">
                        <Table responsive className="primary-table-bordered">
                          <thead className="thead-primary">
                            <tr>
                              <th scope="col">Pf1</th>
                              <th scope="col">pf2</th>
                              <th scope="col">Num Factura</th>
                              <th scope="col">Monto</th>
                              <th scope="col">Vencimiento</th>
                              <th scope="col">Opciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            {items3.map((items3, index) => (
                              <tr key={index}>
                                <td>{items3.pf1}</td>
                                <td>{items3.pf2}</td>
                                <td>{items3.numerofactura}</td>
                                <td>{items3.totalFactura}</td>
                                <td>{items3.vencimiento}</td>
                                <td><Button>Opciones</Button></td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div className="row">
              <div className="col-xl-12 col-xxl-12">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Historial  Facturas </h4>
                      </div>
                      <div className="card-body p-4">
                        <Table responsive className="primary-table-bordered">
                          <thead className="thead-primary">
                            <tr>
                              <th scope="col">ID</th>
                              <th scope="col">Pf1</th>
                              <th scope="col">pf2</th>
                              <th scope="col">Num Factura</th>
                              <th scope="col">Monto</th>
                              <th scope="col">Fecha </th>
                              <th scope="col">Tipo</th>
                            </tr>
                          </thead>
                          <tbody>
                            {items2.map((items2, index) => (
                              <tr key={index}>
                                <td>{items2.idFactura}</td>
                                <td>{items2.pf1}</td>
                                <td>{items2.pf2}</td>
                                <td>{items2.numerofactura}</td>
                                <td>{items2.totalFactura}</td>
                                <td>{items2.fechafactura}</td>
                                <td>{items2.tipoFactura}</td>

                              </tr>
                            ))}

                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        );
      })}



      {reducer.isShow && (
        <ModalAgregarClientes
          estado={reducer.isShow}
          onCloseModal={onCloseModal}
          Editdata={items}
           onReturnValue={onReturnValue}
        />
      )}

    </>
  )
}
export default FichaCliente;