import React, {
    Fragment,
    useCallback,
    useEffect,
    useState,
    useMemo,
  } from "react";
  import { Link } from "react-router-dom";
  import { Card, Button } from "react-bootstrap";
  import {
    useTable,
    useGlobalFilter,
    useFilters,
    usePagination,
  } from "react-table";
  import { COLUMNS2 } from "./Columns";
  import { GlobalFilter } from "./GlobalFilter";
  //import './table.css';
  import "./filtering.css";
  import { TailSpin } from  'react-loader-spinner'
  import { getAllFacturacionCompra} from "../../../Api/facturacion";
  const Compras = () => {
    const [loading, setLoading] = useState(true);
    const [data, setdata] = useState([]);
    const columns = useMemo(() => COLUMNS2, []);
    const assigmentValue = useCallback(async () => {
      try {
       const result = await getAllFacturacionCompra();
        setdata([...result]);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    });
    useEffect(() => {
      assigmentValue();
    }, []);
    const tableInstance = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0 },
      },
      useFilters,
      useGlobalFilter,
      usePagination
    );
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      state,
      page,
      gotoPage,
      pageCount,
      pageOptions,
      nextPage,
      previousPage,
      canNextPage,
      canPreviousPage,
      setGlobalFilter,
    } = tableInstance;
  
    const { globalFilter, pageIndex } = state;
  
  
  
    
   
  
    return (
      <Fragment>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body pb-0">
              <div className="row justify-content-between">
                <div className="col-auto">
                  <Card.Title>Compras</Card.Title>
                </div>
                <div className="col-auto">
                  <h5>
                    <Button>
                      <Link to="/generarcompra" style={{ color: "white" }}>
                        Generar Factura de Compra
                      </Link>
                    </Button>
                  </h5>
                </div>
              </div>
            </div>
            <div className="chart-wrapper">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                    />
                  <TailSpin
                  height="30"
                  width="30"
                  color="Black"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={loading}
                  />
                  <br></br>
                    <table
                      {...getTableProps()}
                      className="table dataTable display"
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th {...column.getHeaderProps()}>
                                {column.render("Header")}
                                {column.canFilter
                                  ? column.render("Filter")
                                  : null}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                  
                      <tbody {...getTableBodyProps()} className="">
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {" "}
                                    <Link to= "/FichaFacturaCompra" state={{ id: row.values.idFactura }} >
                                      {cell.render("Cell")}{" "}
                                    </Link>{" "}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-between">
                      <span>
                        Page{" "}
                        <strong>
                          {pageIndex + 1} of {pageOptions.length}
                        </strong>
                        {""}
                      </span>
                      <span className="table-index">
                        Go to page :{" "}
                        <input
                          type="number"
                          className="ml-2"
                          defaultValue={pageIndex + 1}
                          onChange={(e) => {
                            const pageNumber = e.target.value
                              ? Number(e.target.value) - 1
                              : 0;
                            gotoPage(pageNumber);
                          }}
                        />
                      </span>
                    </div>
                    <div className="text-center mb-3">
                      <div className="filter-pagination  mt-3">
                        <button
                          className=" previous-button"
                          onClick={() => gotoPage(0)}
                          disabled={!canPreviousPage}
                        >
                          {"<<"}
                        </button>
  
                        <button
                          className="previous-button"
                          onClick={() => previousPage()}
                          disabled={!canPreviousPage}
                        >
                          Previous
                        </button>
                        <button
                          className="next-button"
                          onClick={() => nextPage()}
                          disabled={!canNextPage}
                        >
                          Next
                        </button>
                        <button
                          className=" next-button"
                          onClick={() => gotoPage(pageCount - 1)}
                          disabled={!canNextPage}
                        >
                          {">>"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </Fragment>
    );
  };
  export default Compras;