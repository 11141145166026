import React from "react";

const FacturaContado = () => {
    return (
      
       
        <div>hola</div>
       
    )
}
export default FacturaContado;