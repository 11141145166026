import { CALL_API2 } from "../Utils/const";
import { getAuthToken } from "../context/auth-context";

export async function getAllUsuarios() {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/usuario`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}

export async function getUsuarioById(idUsuario) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API2}/usuario/${idUsuario}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }
  return data.result;
}

export async function getUserCredentials() {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API2}/user-credentials`, {
    headers: { Authorization: "Bearer " + token },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message);
  }
  return data.userData;
}

export async function signUpUser(userObject) {
  const { usuario, password, cedula, nombre } = userObject;
  const token = getAuthToken();
  const response = await fetch(`${CALL_API2}/signup`, {
    method: "POST",
    headers: {
      headers: { Authorization: `Bearer  ${token}` },
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      usuario: usuario,
      password: password,
      cedula: cedula,
      nombre: nombre,
    }),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data.isSaved;
}

export async function login(usuarioObject) {
  const { usuario, password } = usuarioObject;
  const response = await fetch(`${CALL_API2}/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      usuario: usuario,
      password: password,
    }),
  });

  const data = await response.json();
  if (!response.ok) {
    console.log(data);
    throw new Error(data.message);
  }

  return data.token;
}
