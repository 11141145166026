import { CALL_API2 } from "../Utils/const";
import { getAuthToken } from "../context/auth-context";

export async function getAllPuntoVenta() {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/punto-venta`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}

export async function getPuntoVentaById(idPunto) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API2}/punto-venta/${idPunto}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }
  return data.result;
}

export async function savePuntoVenta(puntoObject) {
 
  const { isNew, idPunto } = puntoObject;
  const token = getAuthToken();
  let urlSend = `${CALL_API2}/punto-venta`;

  let method = "POST";

  if (puntoObject.isNew) delete puntoObject.isNew;
  if (puntoObject.idPunto) delete puntoObject.idPunto;

  if (!isNew) {
    urlSend = `${urlSend}/${idPunto}`;
    method = "PUT";
  }

  const result = await fetch(urlSend, {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(puntoObject),
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.isSaved;
}

export async function deletePuntoVenta(idPunto) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API2}/punto-venta/${idPunto}`, {
    headers: { Authorization: `Bearer ${token}` },
    method: "DELETE",
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }
  return data.isDeleted;
}
